import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { RootRoutes } from 'src/app/enums/routes';
import { NavController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class SecureInnerPagesGuard implements CanLoad {
    constructor(
        private navCtrl: NavController,
        private authService: AuthService
    ) {}

    canLoad(): Observable<boolean> {
        return this.authService.isAuthenticated.pipe(
            filter((val) => val !== null),
            take(1),
            map((isAuthenticated) => {
                if (isAuthenticated) {
                    void this.navCtrl.navigateForward(RootRoutes.ROOT, {
                        replaceUrl: true,
                    });
                } else {
                    return true;
                }
            })
        );
    }
}
