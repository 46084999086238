import { Component, OnInit, Self } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertController } from '@ionic/angular';
import { LangChangeService } from 'src/app/services/lang-change.service';
import { PinCodeService } from 'src/app/services/pin-code.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ScannerService } from 'src/app/services/scanner.service';
import { NgOnDestroy } from 'src/app/services/ng-on-destroy.service';
import { AuthEventService } from 'src/app/services/auth-event.service';
import { forkJoin, from, Observable, Subscription, fromEvent } from 'rxjs';

@Component({
    selector: 'app-root',
    template: `
        <ion-app>
            <ion-router-outlet #routerOutlet="outlet"></ion-router-outlet>
        </ion-app>
    `,
    providers: [NgOnDestroy],
})
export class AppComponent implements OnInit {
    public onlineEvent: Observable<Event>;
    public offlineEvent: Observable<Event>;
    public subsctiptions: Subscription[] = [];

    public connectionStatusMessage: string;
    public connectionStatus: string;

    constructor(
        @Self() private ngOnDestroy$: NgOnDestroy,
        private readonly authService: AuthService,
        private readonly translateService: TranslateService,
        private readonly swUpdate: SwUpdate,
        private readonly alertController: AlertController,
        private readonly langChangeService: LangChangeService,
        private readonly pinCodeService: PinCodeService,
        private readonly scannerService: ScannerService,
        private readonly authEventService: AuthEventService
    ) {}

    #ofAppBuild$(): Observable<void> {
        return this.swUpdate.available.pipe(
            switchMap(() =>
                from(
                    this.alertController.create({
                        backdropDismiss: false,
                        subHeader: this.translateService.instant('Update'),
                        message: this.translateService.instant(
                            'New version available. Load New Version?'
                        ),
                        buttons: [
                            this.translateService.instant('Cancel'),
                            {
                                text: this.translateService.instant('Ok'),
                                handler: () => location.reload(),
                            },
                        ],
                    })
                )
            ),
            switchMap((alert) => from(alert.present()))
        );
    }

    async ngOnInit() {
        const app$: Observable<any>[] = [
            this.langChangeService.onLangChange$(),
            this.langChangeService.currentLang$(),
            this.langChangeService.getLangList$(),
            this.authEventService.isAuthenticated$(),
            this.pinCodeService.pageVisibility$(),
        ];

        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');

        this.subsctiptions.push(
            this.onlineEvent.subscribe((e) => {
                this.connectionStatusMessage = 'Internet OK';
                this.connectionStatus = 'Online';
            })
        );

        this.subsctiptions.push(
            this.offlineEvent.subscribe((e) => {
                this.connectionStatusMessage = 'No Internet';
                this.connectionStatus = 'Offline';
            })
        );

        if (this.swUpdate.isEnabled) {
            app$.push(this.#ofAppBuild$());
        }

        forkJoin(app$).pipe(takeUntil(this.ngOnDestroy$)).subscribe();

        this.langChangeService.initLang();
        await this.authService.loadToken();
    }
}
