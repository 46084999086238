import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { BottomOnboardToolbarComponent } from 'src/app/components/bottom-onboard-toolbar/bottom-onboard-toolbar.component';
import { CounterComponent } from 'src/app/components/counter/counter.component';
import { TabsComponent } from 'src/app/components/tabs/tabs.component';
import { ToastResultScanComponent } from 'src/app/components/toast-result-scan/toast-result-scan.component';
import { TopOnboardToolbarComponent } from 'src/app/components/top-onboard-toolbar/top-onboard-toolbar.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PrimaryButtonComponent } from 'src/app/components/primary-button/primary-button.component';
import { ShowHidePasswordComponent } from 'src/app/components/show-hide-password/show-hide-password.component';
import { OrderCardComponent } from 'src/app/components/order-card/order-card.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { MobileDialComponent } from 'src/app/components/mobile-dial/mobile-dial.component';
import { OrderStatusTabsComponent } from 'src/app/components/order-status-tabs/order-status-tabs.component';

@NgModule({
    declarations: [
        BottomOnboardToolbarComponent,
        CounterComponent,
        TabsComponent,
        ToastResultScanComponent,
        TopOnboardToolbarComponent,
        PrimaryButtonComponent,
        ShowHidePasswordComponent,
        OrderCardComponent,
        HeaderComponent,
        MobileDialComponent,
        OrderStatusTabsComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        DirectivesModule,
    ],
    exports: [
        BottomOnboardToolbarComponent,
        CounterComponent,
        TabsComponent,
        ToastResultScanComponent,
        TopOnboardToolbarComponent,
        PrimaryButtonComponent,
        ShowHidePasswordComponent,
        OrderCardComponent,
        HeaderComponent,
        MobileDialComponent,
        OrderStatusTabsComponent,
    ],
})
export class ComponentsModule {}
