import { CheckInternetSpeed } from 'src/app/services/check-internet-speed.service';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    Self,
    Input,
} from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { OrderService } from 'src/app/services/order.service';
import { OrderStatusesTextEnum } from 'src/app/enums/order-statuses-text.enum';
import { OrderModel } from 'src/app/models/order.model';
import { ChangeStatusOrderService } from 'src/app/services/change-status-order.service';
import { ScannerService } from './../../services/scanner.service';
import { leftToRightInOutAnimation } from 'src/app/animations/left-to-right-in-out.animation';
import { OrderRoutes, RootRoutes } from 'src/app/enums/routes';
import { NgOnDestroy } from 'src/app/services/ng-on-destroy.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { ChangeStatusCounterModel } from 'src/app/models/change-status-counter.model';
import { ResponseStatusEnum } from 'src/app/models/response-status.model';
import { PendingDateService } from 'src/app/services/pending-date.service';
import { inOutAnimationFooter } from 'src/app/animations/in-out-footer.animation';

@Component({
    selector: 'app-manual-changeover-status',
    templateUrl: './manual-changeover-status.component.html',
    styleUrls: ['./manual-changeover-status.component.scss'],
    providers: [NgOnDestroy],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [leftToRightInOutAnimation, inOutAnimationFooter],
})
export class ManualChangeoverStatusComponent implements OnInit {
    @Input() propStatus: OrderStatusesTextEnum;
    selectedStatus: OrderStatusesTextEnum;
    selectedOrders: OrderModel[];
    stateHeader: 'main' | 'searchField';
    searchTerm: string;
    lastSelectedOrder: OrderModel | null = null;
    counter = 0;
    timerTimeHasExpired = true;
    changeStatusCounterModel: ChangeStatusCounterModel;
    defaultUrlForBackButton = `${RootRoutes.ROOT}/${RootRoutes.ORDERS}/${OrderRoutes.ORDER_WAITING}`;

    get ordersBySelectedStatus(): OrderModel[] {
        if (this.propStatus) {
            return this.orderService.ordersToChangeStatus.filter(
                (order) => order.statusText === this.propStatus
            );
        } else {
            return this.orderService.ordersToChangeStatus.filter(
                (order) => order.statusText === this.selectedStatus
            );
        }
    }

    get allOrdersSinceComplete(): OrderModel[] {
        return this.pendingDateService.allOrdersSinceComplete(
            this.pendingDateService.pendingDate
        );
    }

    get ordersTaken(): OrderModel[] {
        return this.pendingDateService.ordersTaken(
            this.pendingDateService.pendingDate
        );
    }

    constructor(
        @Self() private readonly ngOnDestroy$: NgOnDestroy,
        private readonly cdr: ChangeDetectorRef,
        public readonly orderService: OrderService,
        public readonly changeStatusOrderService: ChangeStatusOrderService,
        private readonly navCtrl: NavController,
        private readonly modalCtrl: ModalController,
        public readonly loadingService: LoadingService,
        private readonly pendingDateService: PendingDateService,
        private readonly scannerService: ScannerService,
        private readonly checkInternetSpeed: CheckInternetSpeed
    ) {}

    ngOnInit() {
        if (this.propStatus) {
            this.selectedStatus = this.propStatus;
        }
        this.#initManualChangeoverStatusEnvs();

        this.changeStatusOrderService
            .changeStatusOfSelectedOrders$()
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe({
                next: (model) => {
                    this.changeStatusCounterModel = model;

                    if (!this.lastSelectedOrder) {
                        this.lastSelectedOrder =
                            this.selectedOrders[this.selectedOrders.length - 1];
                    }

                    if (model.resStatus === ResponseStatusEnum.SUCCESS) {
                        this.#initManualChangeoverStatusEnvs();
                        this.clearSelectedOrders();
                    }

                    this.cdr.detectChanges();
                },
            });
    }

    ionViewWillLeave() {
        this.ngOnDestroy$.clearSubscriptions();
    }

    switchStateOfHeader(state?: 'main' | 'searchField') {
        this.stateHeader = state || 'main';
    }

    changeStatus() {
        this.checkInternetSpeed.testSpeed();
        this.changeStatusOrderService.statusChangeClick.next(
            this.selectedOrders
        );
    }

    toOrderDetail(order: OrderModel) {
        if (this.changeStatusCounterModel.remainingSeconds === 0) {
            from(
                this.navCtrl.navigateForward(
                    `${RootRoutes.ROOT}/${RootRoutes.ORDERS}/order-detail/${order.id}`,
                    { queryParams: { orderId: order.id } }
                )
            )
                .pipe(
                    switchMap(() => from(this.close())),
                    takeUntil(this.ngOnDestroy$)
                )
                .subscribe();
        }
    }

    #initManualChangeoverStatusEnvs() {
        this.selectedOrders = [];
        this.stateHeader = 'main';
    }

    selectAllOrders() {
        this.selectedOrders = this.ordersBySelectedStatus;
    }

    clearSelectedOrders() {
        this.selectedOrders = [];
    }

    openScan() {
        this.scannerService.openModal();
    }

    async close() {
        this.changeStatusOrderService.notifyChangeStatus();
        this.scannerService.switchScanButtonDisplay(true);
        await this.modalCtrl.dismiss();
    }

    hasSelectedOrderById(orderId: number): boolean {
        const idx = this.selectedOrders.findIndex(
            (order) => order.orderId === orderId
        );

        return idx !== -1;
    }

    onSelectedStatus(selectedStatus: OrderStatusesTextEnum) {
        this.selectedStatus = selectedStatus;
        this.cdr.detectChanges();
    }

    toggleSelectedOrder(order: OrderModel) {
        if (this.counter > 0) {
            return;
        }

        this.stateHeader = this.stateHeader === 'searchField' ? 'main' : 'main';

        if (this.hasSelectedOrderById(order.orderId)) {
            this.selectedOrders = this.selectedOrders.filter(
                (el) => el.id !== order.id
            );

            return;
        }

        this.selectedOrders.push(order);
    }

    cancelSnack() {
        this.changeStatusOrderService.statusChangeCancel.next();
        this.lastSelectedOrder = null;
        this.cdr.detectChanges();
    }
}
