import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { IFinanceGroup, IFinanceQuery } from 'src/app/interfaces';
import { CheckInternetSpeed } from 'src/app/services/check-internet-speed.service';

@Injectable({
    providedIn: 'root',
})
export class FinanceApiService {
    constructor(
        private readonly http: HttpClient,
        private checkInternetSpeed: CheckInternetSpeed
    ) {}

    fetchFinanceList(): Observable<Array<IFinanceQuery>> {
        return this.http.get('/finance').pipe(pluck('data'));
    }

    fetchFinanceById(financeId: number): Observable<IFinanceQuery> {
        return this.http.get(`/finance/${financeId}`).pipe(pluck('data'));
    }

    fetchFinanceGroup(): Observable<Array<IFinanceGroup>> {
        this.checkInternetSpeed.testSpeed();
        return this.http.get('/finance/group').pipe(pluck('data'));
    }
}
