import { IOrderWrapper } from 'src/app/interfaces';
import { OrderStatusesEnum } from 'src/app/enums/order-statuses.enum';
import { PaymentTypeEnum } from 'src/app/enums/payment-type.enum';
import { OrderStatusesTextEnum } from 'src/app/enums/order-statuses-text.enum';
import { ProductModel } from 'src/app/models/product.model';
import { StatusesFlowModel } from 'src/app/models/statuses-flow.model';
import { Mixin } from 'src/app/mixins/mixin.decorator';
import { CustomerAddressModel } from 'src/app/models/customer-address.model';
import { AvailableStatusesModel } from 'src/app/models/available-statuses.model';

@Mixin(CustomerAddressModel, AvailableStatusesModel)
export class OrderModel
    implements CustomerAddressModel, AvailableStatusesModel
{
    orderId: number;
    courierId: number;
    deliveryDate: string;
    sortOrder: number;
    deliveryFrom: string;
    deliveryTo: string;
    deliveryDesiredDate: string;
    customerFullName: string;
    customerPhone: string;
    customerAddress: string;
    orderPrice: number;
    deliveryPrice: number;
    currencyCode: string;
    track: string;
    paymentType: PaymentTypeEnum;
    createdAt: string;
    totalPrice: number;
    products: Map<string, ProductModel> = new Map<string, ProductModel>();
    statusesFlow: StatusesFlowModel;
    countryId: number;
    comment: string | null;
    currentUserRole: string;
    pendingStatus: OrderStatusesEnum | null = null;
    pendingSubStatus: number | null = null;
    availableStatuses: OrderStatusesEnum[];
    currentStatus: OrderStatusesEnum;
    nextStatusAvailable: OrderStatusesEnum | undefined;
    previousStatusAvailable: OrderStatusesEnum | undefined;
    isOrderOnWay: boolean;
    statusText: OrderStatusesTextEnum;
    previousStatusText: OrderStatusesTextEnum;
    addressText: string;
    googleTag: string;

    get productsValue(): ProductModel[] {
        return [...this.products.values()];
    }

    constructor(public id: number) {}

    static fromDto(data: IOrderWrapper, detailMode = false): OrderModel {
        const model = new OrderModel(data.id);

        model.orderId = data.order_id;
        model.courierId = data.courier_id;
        model.deliveryDate = data.delivery_date;
        model.sortOrder = data.sort_order;
        model.deliveryFrom = data.order.delivery_from;
        model.deliveryTo = data.order.delivery_to;
        model.deliveryDesiredDate = data.order.delivery_desired_date;
        model.customerFullName = data.order.customer_full_name;
        model.customerPhone = data.order.customer_phone;
        model.customerAddress = data.order.customer_address;
        model.orderPrice = data.order.order_price;
        model.deliveryPrice = data.order.delivery_price;
        model.currencyCode = data.order.currency_code;
        model.track = data.order.track;
        model.paymentType = data.order.payment_type;
        model.currentStatus = data.order.status;
        model.createdAt = data.order.created_at;
        model.totalPrice = data.order.total;

        if (detailMode) {
            model.countryId = data.order.country_id;
            model.comment = data.order.comment;
            model.totalPrice = data.order.total;

            (data.order.products || []).forEach((x) => {
                const product = ProductModel.fromDto(x);

                model.products.set(product.productId, product);
            });
        }

        return model;
    }
}
