export const getLangName = (charCode: string): string => {
    switch (charCode) {
        case 'en': return 'English';
        case 'ru': return 'Русский';
        case 'es': return 'Español';
        default: {
            console.error(`There is no translation for this ${charCode} char code`);
        }
    }
};
