import { Injectable } from '@angular/core';
import { OrderModel } from 'src/app/models/order.model';
import { ActionSheetButton } from '@ionic/core/dist/types/components/action-sheet/action-sheet-interface';
import { OrderStatusesEnum } from 'src/app/enums/order-statuses.enum';
import { TranslateService } from '@ngx-translate/core';
import currencyToSymbolMap from 'currency-symbol-map/map';
import {
    ActionSheetController,
    ActionSheetOptions,
    AlertController,
    AlertOptions,
} from '@ionic/angular';
import { OrderService } from 'src/app/services/order.service';
import {
    concat,
    forkJoin,
    from,
    merge,
    NEVER,
    Observable,
    ObservedValueOf,
    ObservableInput,
    of,
    Subject,
    throwError,
} from 'rxjs';
import { OrderApiService } from 'src/app/api/order-api-service';
import { IOrderStatusUpdate } from 'src/app/interfaces';
import {
    catchError,
    finalize,
    map,
    mapTo,
    pluck,
    reduce,
    scan,
    startWith,
    switchMap,
    tap,
    take,
} from 'rxjs/operators';
import { isArray } from 'rxjs/internal-compatibility';
import { ToastService } from 'src/app/services/toast.service';
import { FlowIncorrectlyError } from 'src/app/errors/flow-incorrectly.error';
import { FinanceService } from 'src/app/services/finance.service';
import { ChangeStatusCounterModel } from 'src/app/models/change-status-counter.model';
import { ResponseStatusEnum } from 'src/app/models/response-status.model';
import { OrderTimersService } from 'src/app/services/order-timers.service';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';
import { IOrderSubStatuses } from '../interfaces/index';
import { watch } from 'rxjs-watcher';

@Injectable({
    providedIn: 'root',
})
export class ChangeStatusOrderService {
    public statusChangeClick: Subject<OrderModel[]> = new Subject();
    public statusChangeCancel: Subject<unknown> = new Subject();
    public changeStatusNotifier: Subject<null> = new Subject();
    public orderSubStatuses: IOrderSubStatuses[];

    constructor(
        private readonly translateService: TranslateService,
        private readonly actionSheetController: ActionSheetController,
        private readonly alertController: AlertController,
        private readonly orderApiService: OrderApiService,
        private readonly orderService: OrderService,
        private readonly toastService: ToastService,
        private readonly financeService: FinanceService,
        private readonly orderTimersService: OrderTimersService,
        private readonly localStorageService: LocalStorageService,
        private readonly userService: UserService
    ) {}

    static getUniqNextStatuses(orders: OrderModel[]): OrderStatusesEnum[] {
        return [
            ...new Set(orders.map((order) => order.availableStatuses).flat()),
        ];
    }

    notifyChangeStatus() {
        this.changeStatusNotifier.next();
    }

    #generateOrderSubStatuses$() {
        const sub = this.orderApiService.getSubStatuses().pipe(
            tap((res) => {
                // console.log('res', res);
                this.orderSubStatuses = res[4].sub_statuses.reduce(
                    (acc, val) => {
                        acc.push({
                            id: val.id,
                            name: val.name,
                            description: val.description,
                            countryId: val.country_id,
                            organizationId: val.organization_id,
                        });
                        return acc;
                    },
                    []
                );
                // console.log('OrderSubs', this.orderSubStatuses);
                this.orderSubStatuses.push({
                    id: 101,
                    name: 'Go back',
                    description: '',
                    countryId: 0,
                    organizationId: 0,
                });
                // console.log('OrderSubs', this.orderSubStatuses);
            })
        );
        return sub;
    }

    #mapOrderSubStatusesButtons(
        countOfStatusesToTransition:
            | (OrderStatusesEnum[] | OrderStatusesEnum)[]
            | IOrderSubStatuses[]
            | number[]
    ) {
        // console.log('mapOrderSubs');
        return countOfStatusesToTransition.map((status, i) => {
            const statusObj = this.orderSubStatuses.filter(
                (value) => value.id === status
            );
            const icon =
                i === countOfStatusesToTransition.length - 1
                    ? `../../assets/icons/subStatuses/back.svg`
                    : `../../assets/icons/subStatuses/${i}.svg`;
            // console.log('statusOBJ', statusObj);
            // console.log('status', status);
            return {
                cssClass: `${
                    statusObj[0].id === 101
                        ? 'changeStatus__button backButton'
                        : 'changeStatus__button'
                }`,
                text: this.translateService.instant(statusObj[0].name),
                icon,
                role: status.toString(),
            };
        });
    }

    #mapOrderStatusesButtons(
        countOfStatusesToTransition:
            | (OrderStatusesEnum[] | OrderStatusesEnum)[]
            | IOrderSubStatuses[]
            | number[]
    ) {
        return countOfStatusesToTransition.map((status) => {
            switch (status) {
                case OrderStatusesEnum.COURIER_ASSIGN: {
                    return {
                        cssClass: 'changeStatus__button',
                        text: this.translateService.instant(
                            'Waiting to receive'
                        ),
                        icon: 'checkmark-outline',
                        role: status.toString(),
                    };
                }

                case OrderStatusesEnum.IN_DELIVERY: {
                    return {
                        cssClass: 'changeStatus__button',
                        text: this.translateService.instant('In Progress'),
                        icon: 'cash-outline',
                        role: status.toString(),
                    };
                }

                case OrderStatusesEnum.ON_WAY: {
                    return {
                        cssClass: 'changeStatus__button',
                        text: this.translateService.instant('Active'),
                        icon: 'checkmark-outline',
                        role: status.toString(),
                    };
                }

                case OrderStatusesEnum.BUYOUT: {
                    return {
                        cssClass: 'changeStatus__button',
                        text: this.translateService.instant('Buyout'),
                        icon: 'car-outline',
                        role: status.toString(),
                    };
                }

                case OrderStatusesEnum.REJECTED: {
                    return {
                        cssClass: ['changeStatus__button', 'rejected'],
                        text: this.translateService.instant('Reject'),
                        icon: 'close-circle-outline',
                        role: status.toString(),
                    };
                }
            }
        });
    }

    #generateButtonsToChangeStatuses(
        orderModel: OrderModel,
        countOfStatusesToTransition:
            | (OrderStatusesEnum[] | OrderStatusesEnum)[]
            | IOrderSubStatuses[]
            | number[]
    ): ActionSheetButton[] {
        if (!orderModel.pendingStatus) {
            return this.#mapOrderStatusesButtons(countOfStatusesToTransition);
        } else {
            return this.#mapOrderSubStatusesButtons(
                countOfStatusesToTransition
            );
        }
    }

    #showPossibleTransitionStatuses(
        orderModel: OrderModel,
        countOfStatusesToTransition:
            | (OrderStatusesEnum[] | OrderStatusesEnum)[]
            | IOrderSubStatuses[]
            | number[]
    ): Observable<number> {
        let header = '';
        let subHeader = '';
        if (!orderModel.pendingStatus) {
            header = `${this.translateService.instant('Order ID')}: ${
                orderModel.orderId
            }`;
            subHeader = this.translateService.instant(orderModel.statusText);
        } else {
            header = this.translateService.instant('Select substatus');
            subHeader = this.translateService.instant('Reject');
        }
        const actionSheetOpts: ActionSheetOptions = {
            cssClass: 'actionSheetController__orderStatuses',
            header,
            subHeader,
            buttons: this.#generateButtonsToChangeStatuses(
                orderModel,
                countOfStatusesToTransition
            ),
            htmlAttributes: {
                // @ts-ignore
                'data-cy': 'actionSheetActiveOrder',
            },
        };
        // console.log('ActionSheetOpts', actionSheetOpts);
        // console.log(orderModel.pendingStatus);

        return from(this.actionSheetController.create(actionSheetOpts)).pipe(
            // tap((actSht) => console.log('ActSht', actSht)),
            switchMap((actionSheet) =>
                forkJoin([
                    from(actionSheet.present()),
                    from(actionSheet.onDidDismiss()),
                ])
            ),
            map(([_, res]) => res),
            // tap((res) => {
            // console.log('resss', res);
            // }),
            pluck('role'),
            map((role) => (!isNaN(Number(role)) ? Number(role) : 0))
        );
    }

    #showDeliveryConfirmationBox(orderModel: OrderModel): Observable<number> {
        const alertOpts: AlertOptions = {
            header: this.translateService.instant('Has the order been buyout?'),
            message: this.translateService.instant(
                'Did you receive payment of {{totalPrice}}{{currencyCode}} for the order {{orderId}}?',
                {
                    totalPrice: orderModel.totalPrice,
                    orderId: orderModel.orderId,
                    currencyCode: currencyToSymbolMap[orderModel.currencyCode],
                }
            ),
            buttons: [
                {
                    text: this.translateService.instant('Return'),
                    role: null,
                },
                {
                    text: this.translateService.instant('Confirm'),
                    role: '1',
                },
            ],
            htmlAttributes: {
                // @ts-ignore
                'data-cy': 'alertBuyoutConfirm',
            },
        };

        return from(this.alertController.create(alertOpts)).pipe(
            switchMap((alert) =>
                forkJoin([from(alert.present()), from(alert.onDidDismiss())])
            ),
            map(([_, res]) => res),
            pluck('role'),
            map((role) => (!isNaN(Number(role)) ? Number(role) : 0))
        );
    }

    #showAlertOrderCannotBeRejected(): Observable<number> {
        const alertOpts = {
            message: this.translateService.instant(
                'You need to make at least one call to the customer in order to reject an order'
            ),
            buttons: [this.translateService.instant('Ok')],
        };

        return from(this.alertController.create(alertOpts)).pipe(
            switchMap((alert) => from(alert.present())),
            map(() => 0)
        );
    }

    #openActionSheet(
        orderModel: OrderModel,
        countOfStatusesToTransition:
            | (OrderStatusesEnum[] | OrderStatusesEnum)[]
            | IOrderSubStatuses[]
            | number[]
    ): Observable<OrderModel | null> {
        return this.#showPossibleTransitionStatuses(
            orderModel,
            countOfStatusesToTransition
        ).pipe(
            // tap((res) => console.log('re3s', res)),
            switchMap((status) => {
                // console.log('irderStat', status);
                if (status === 101) {
                    orderModel.pendingSubStatus = null;
                    orderModel.pendingStatus = null;
                    return this.#changeOrderStatus$([orderModel]);
                }
                if (!orderModel.pendingStatus) {
                    if (status === OrderStatusesEnum.BUYOUT) {
                        return this.#showDeliveryConfirmationBox(
                            orderModel
                        ).pipe(map((res) => (Boolean(res) ? status : 0)));
                    }

                    if (status === OrderStatusesEnum.REJECTED) {
                        // console.log('STATUUUUUUS', status);
                        // console.log(this.orderSubStatuses);
                        return this.localStorageService
                            .hasCallAttemptByOrderId$(
                                orderModel.orderId,
                                this.userService.userId
                            )
                            .pipe(
                                switchMap((res) =>
                                    !res
                                        ? this.#showAlertOrderCannotBeRejected()
                                        : this.#chooseSubStatus(
                                              orderModel,
                                              status
                                          )
                                )
                            );
                    }
                } else {
                    orderModel.pendingSubStatus = status;
                }

                return of(status);
            }),
            map((pendingStatus) => {
                if (pendingStatus) {
                    if (!orderModel.pendingStatus) {
                        orderModel.pendingStatus = pendingStatus;
                    }
                    return orderModel;
                }

                orderModel.pendingStatus = null;
                return null;
            })
        );
    }

    #chooseSubStatus(
        orderModel: OrderModel,
        status: number
    ): ObservableInput<any> {
        orderModel.pendingStatus = status;
        if (this.orderSubStatuses.length) {
            // console.log(this.orderSubStatuses);
            // console.log(orderModel);
            // console.log(this.userService.user);
            const subs = this.orderSubStatuses.reduce((acc, cur) => {
                if (
                    (cur.countryId === orderModel.countryId &&
                        cur.organizationId ===
                            this.userService.user.organizationId) ||
                    cur.id === 101
                ) {
                    acc.push(cur.id);
                }
                // console.log('1', cur.countryId, orderModel.countryId);
                // console.log(
                //     '2',
                //     cur.organizationId,
                //     this.userService.user.organizationId
                // );
                // console.log(
                //     '3',
                //     cur.countryId === orderModel.countryId &&
                //         cur.organizationId ===
                //             this.userService.user.organizationId
                // );
                return acc;
            }, []);
            if (subs.length > 1) {
                return this.#openActionSheet(orderModel, subs);
            } else {
                return of(status);
            }
            // return this.#openActionSheet(orderModel, subs);
        } else {
            return of(status);
        }
    }

    #sendRequestsForChangeOfStatus(
        orders: OrderModel[]
    ): Observable<IOrderStatusUpdate[]> {
        const arr$: Observable<IOrderStatusUpdate>[] = orders.map((order) =>
            this.orderApiService.changeOrderStatus(
                order.orderId,
                order.pendingStatus,
                order.pendingSubStatus
            )
        );

        return forkJoin(arr$);
    }

    #changeOrderStatus$(
        orders: OrderModel[]
    ): Observable<ObservedValueOf<Observable<OrderModel>> | OrderModel[]> {
        const data = ChangeStatusOrderService.getUniqNextStatuses(orders);

        if (data.length === 0) {
            return throwError(new FlowIncorrectlyError());
        }

        if (data.length === 1) {
            orders.forEach((order) => (order.pendingStatus = data[0]));

            return of(orders);
        }

        const arr$: Observable<OrderModel>[] = orders.map((order) => {
            order.pendingStatus = null;
            order.pendingSubStatus = null;
            return this.#openActionSheet(order, data);
        });
        // console.log('arr', arr$);

        // console.log('data', data);
        return concat(...arr$);
    }

    #timerToSend$(
        state: ChangeStatusCounterModel,
        selectedOrders: OrderModel[]
    ): Observable<ChangeStatusCounterModel> {
        return this.orderTimersService.backTimer$(state.value).pipe(
            tap((i) => (state.remainingSeconds = i)),
            switchMap(() => {
                if (!!!state.remainingSeconds) {
                    return this.#statusChangeTimerHandler$(selectedOrders).pipe(
                        switchMap(() => {
                            state.resStatus = ResponseStatusEnum.SUCCESS;

                            return of(state);
                        })
                    );
                }
                return of(state);
            }),
            catchError(() => {
                state.resStatus = ResponseStatusEnum.ERROR;

                return of(state);
            })
        );
    }

    #errorHandler(err: Error): Observable<never> {
        if (err instanceof FlowIncorrectlyError) {
            return this.toastService
                .showWarningToast$(this.translateService.instant(err.message))
                .pipe(switchMap((_) => NEVER));
        }

        return throwError(err);
    }

    #statusChangeClick$() {
        const statusChangeClick$ = this.statusChangeClick.asObservable();

        return statusChangeClick$.pipe(
            map((orders: OrderModel[]) => ({
                count: true,
                orders,
                value: 5,
                resStatus: ResponseStatusEnum.EMPTY,
            }))
        );
    }

    #statusChangeCancel$() {
        const defaultData = new ChangeStatusCounterModel();
        const statusChangeCancel$ = this.statusChangeCancel.asObservable();

        return statusChangeCancel$.pipe(mapTo(defaultData));
    }

    #statusChangeTimerHandler$(
        selectedOrders: OrderModel[]
    ): Observable<Observable<never> | void> {
        return this.#sendRequestsForChangeOfStatus(selectedOrders).pipe(
            switchMap(() =>
                this.localStorageService.updateChangeOfStatusDateOrderById$(
                    selectedOrders.map((x) => x.orderId),
                    this.userService.userId
                )
            ),
            switchMap(() => {
                const msg = this.translateService.instant(
                    'Statuses have been successfully changed'
                );

                return this.toastService.showSuccessToast$(msg);
            }),
            catchError((err) =>
                this.toastService
                    .showDangerToast$(err.message)
                    .pipe(map(() => NEVER))
            )
        );
    }

    changeStatusOfSelectedOrders$(): Observable<ChangeStatusCounterModel> {
        const defaultData = new ChangeStatusCounterModel();

        const events$ = merge(
            this.#generateOrderSubStatuses$(),
            this.#statusChangeClick$(),
            this.#statusChangeCancel$()
        );

        return events$.pipe(
            startWith(defaultData),
            scan(
                (
                    state: ChangeStatusCounterModel,
                    curr
                ): ChangeStatusCounterModel => ({ ...state, ...curr }),
                defaultData
            ),
            switchMap((state) => {
                if (state.count && !!state.orders.length) {
                    return this.#changeOrderStatus$(state.orders).pipe(
                        reduce<OrderModel | OrderModel[], OrderModel[]>(
                            (acc, item) => {
                                if (isArray(item)) {
                                    if (
                                        item[0].pendingStatus ===
                                            OrderStatusesEnum.ON_WAY &&
                                        (this.orderService.ordersOnWay.length +
                                            item.length >
                                            2 ||
                                            this.orderService.ordersOnWay
                                                .length >= 2)
                                    ) {
                                        const msg =
                                            this.orderService.ordersOnWay
                                                .length >= 2
                                                ? 'The first, complete the delivery of orders in "On the way" status'
                                                : 'You can not take more than 2 orders';

                                        const sub = this.toastService
                                            .showDangerToast$(
                                                msg,
                                                '../../assets/icons/danger.svg',
                                                'on_the_way_danger_toast'
                                            )
                                            .pipe(take(1))
                                            .subscribe((next) => {
                                                sub.unsubscribe();
                                            });
                                        return acc;
                                    } else {
                                        acc = acc.concat(item);
                                    }
                                }

                                if (item instanceof OrderModel) {
                                    if (
                                        item.pendingStatus ===
                                            OrderStatusesEnum.ON_WAY &&
                                        this.orderService.ordersOnWay.length >=
                                            2
                                    ) {
                                        const sub = this.toastService
                                            .showDangerToast$(
                                                'The first, complete the delivery of orders in On the Way status',
                                                '../../assets/icons/danger.svg'
                                            )
                                            .pipe(take(1))
                                            .subscribe((next) => {
                                                sub.unsubscribe();
                                            });
                                        return acc;
                                    }
                                    acc.push(item);
                                }

                                return acc;
                            },
                            []
                        ),
                        switchMap((selectedOrders) => {
                            if (!!selectedOrders.length) {
                                return this.#timerToSend$(
                                    state,
                                    selectedOrders
                                ).pipe(
                                    finalize(() => {
                                        forkJoin([
                                            this.orderService.initTimersForOrders$(),
                                            this.financeService.getFinanceGroupList$(),
                                        ]);
                                    })
                                );
                            }

                            return NEVER;
                        }),
                        catchError(this.#errorHandler.bind(this))
                    );
                }

                return of(state);
            })
        );
    }

    resetPreviousOrderStatus$(
        order: OrderModel
    ): Observable<IOrderStatusUpdate> {
        return this.orderApiService.changeOrderStatus(
            order.orderId,
            order.previousStatusAvailable
        );
    }
}
