import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';
import { from, Observable, ObservedValueOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private readonly toastController: ToastController) {}

    #createToast(
        options: ToastOptions
    ): Observable<ObservedValueOf<Promise<HTMLIonToastElement>>> {
        return from(this.toastController.create(options));
    }

    showSuccessToast$(message: string): Observable<void> {
        const toastOpts: ToastOptions = {
            message,
            position: 'top',
            duration: 1500,
            color: 'success',
            keyboardClose: true,
            htmlAttributes: {
                // @ts-ignore
                'data-cy': 'toastSuccess',
            },
        };

        return this.#createToast(toastOpts).pipe(
            switchMap((toast) => from(toast.present()))
        );
    }

    showDangerToast$(
        message: string,
        icon?: string,
        cssClass?: string
    ): Observable<void> {
        const toastOpts: ToastOptions = {
            message,
            position: 'top',
            duration: 3000,
            color: 'danger',
            keyboardClose: true,
            htmlAttributes: {
                // @ts-ignore
                'data-cy': 'toastDanger',
            },
            cssClass: cssClass || ' ',
        };
        if (icon) {
            toastOpts.icon = icon;
        }

        return this.#createToast(toastOpts).pipe(
            switchMap((toast) => from(toast.present()))
        );
    }

    showSecondaryToast$(message: string): Observable<void> {
        const toastOpts: ToastOptions = {
            message,
            position: 'top',
            duration: 2000,
            color: 'secondary',
            keyboardClose: true,
            htmlAttributes: {
                // @ts-ignore
                'data-cy': 'toastSecondary',
            },
        };

        return this.#createToast(toastOpts).pipe(
            switchMap((toast) => from(toast.present()))
        );
    }

    showWarningToast$(message: string): Observable<void> {
        const toastOpts: ToastOptions = {
            message,
            position: 'top',
            duration: 2000,
            color: 'warning',
            keyboardClose: true,
            htmlAttributes: {
                // @ts-ignore
                'data-cy': 'toastWarning',
            },
        };

        return this.#createToast(toastOpts).pipe(
            switchMap((toast) => from(toast.present()))
        );
    }
}
