import {
    animate,
    style,
    transition,
    trigger,
    AnimationTriggerMetadata,
} from '@angular/animations';

export const inOutAnimationQrScan: AnimationTriggerMetadata = trigger(
    'inOutAnimationQrScan',
    [
        transition(':enter', [
            style({ transform: 'scale(.1)' }),
            animate('.1s ease-out', style({ transform: 'scale(1)' })),
        ]),
        transition(':leave', [
            style({ transform: 'scale(1)' }),
            animate('.1s ease-in', style({ transform: 'scale(.1)' })),
        ]),
    ]
);
