import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ILangData } from 'src/app/interfaces/lang-list.interface';

@Injectable({
    providedIn: 'root',
})
export class I18nApiService {
    constructor(private readonly http: HttpClient) {}

    getLangList(): Observable<ILangData[]> {
        return this.http.get('/i18n/language-index').pipe(pluck('data'));
    }

    getTranslationData(): Observable<Record<string, any>> {
        return this.http.get('/i18n/translation-index').pipe(pluck('data'));
    }

    addTranslation(category: string, message: string): Observable<any> {
        return this.http
            .post('/i18n/missed-translation', { category, message })
            .pipe(pluck('data'));
    }
}
