import {
    Directive,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
} from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@Directive({
    selector: '[appCounter]',
})
export class CounterDirective implements OnChanges, OnDestroy {
    @Input() counter: number;
    @Input() interval: number;

    @Output() value = new EventEmitter<number>();

    #counterSource$ = new Subject<any>();
    #subscription = Subscription.EMPTY;

    // TODO: Where is the unsubscribe

    constructor() {
        this.#subscription = this.#counterSource$
            .pipe(
                switchMap(({ interval, count }) =>
                    timer(0, interval).pipe(
                        take(count),
                        tap(() => this.value.emit(--count))
                    )
                )
            )
            .subscribe();
    }

    ngOnChanges() {
        this.#counterSource$.next({
            count: this.counter,
            interval: this.interval,
        });
    }

    ngOnDestroy() {
        this.#subscription.unsubscribe();
    }
}
