import { IUserStatistic } from 'src/app/interfaces';

export class UserStatisticModel {
    workDays: number;
    ordersDelivered: number;
    ordersRejected: number;

    static fromDto(data: IUserStatistic): UserStatisticModel {
        const model = new UserStatisticModel();

        model.workDays = data.work_days;
        model.ordersDelivered = data.orders_delivered;
        model.ordersRejected = data.orders_rejected;

        return model;
    }
}
