import { SpeedTestService } from 'ng-speed-test';
import { CheckInternetSpeed } from 'src/app/services/check-internet-speed.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from 'src/app/app.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { environment } from 'src/environments/environment';
import { AuthInterceptor } from 'src/app/interceptors/auth.interceptor';
import {
    MissingTranslationHandler,
    TranslateLoader,
    TranslateModule,
} from '@ngx-translate/core';
import createTranslateLoader from 'src/app/translate/createTranslateLoader';
import { I18nApiService } from 'src/app/api/i18n-api-service';
import { CustomMissingTranslationHandler } from 'src/app/translate/CustomMissingTranslationHandler';
import { LanguageChangeComponent } from 'src/app/modals/language-change/language-change.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { LaravelEchoModule } from 'src/app/laravel-echo/module';
import { COURIER_DATA_UPDATED } from 'src/app/constants/socket-channel-names';
import { ToastService } from 'src/app/services/toast.service';
import { DatePipe } from '@angular/common';

@NgModule({
    declarations: [AppComponent, LanguageChangeComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ComponentsModule,
        IonicModule.forRoot({
            mode: 'md',
        }),
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.pwaModeEnabled,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        TranslateModule.forRoot({
            useDefaultLang: true,
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler,
                deps: [I18nApiService],
            },
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [I18nApiService, ToastService],
            },
        }),
        LaravelEchoModule.forRoot({
            userModel: COURIER_DATA_UPDATED,
            notificationNamespace: 'App',
            options: {
                broadcaster: 'pusher',
                disableStats: false,
                encrypted: true,
                authModule: true,
                disconnectOnLogout: true,
                authEndpoint: `${environment.urlApiBase}/broadcasting/auth`,
                key: environment.pusherAppKey,
                wsHost: environment.wsHost,
                host: environment.wsHost,
                wsPort: environment.wsPort,
                cluster: environment.pusherAppCluster,
            },
        }),
    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: AuthInterceptor,
        },
        CheckInternetSpeed,
        SpeedTestService,
        DatePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
