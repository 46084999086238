import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit, Self } from '@angular/core';
import { Router } from '@angular/router';
import { RootRoutes } from 'src/app/enums/routes';
import { ScannerService } from 'src/app/services/scanner.service';
import { NgOnDestroy } from 'src/app/services/ng-on-destroy.service';
import { IonTabs } from '@ionic/angular';
import { inOutAnimationQrScan } from 'src/app/animations/in-out-qr-scan.animation';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    providers: [NgOnDestroy],
    animations: [inOutAnimationQrScan],
})
export class TabsComponent implements OnInit {
    @Input() isShow = true;
    @Input() isMainTabs = true;

    routes: {
        readonly ORDERS: RootRoutes.ORDERS;
        readonly ROOT: RootRoutes.ROOT;
        readonly FINANCE: RootRoutes.FINANCE;
        readonly PROFILE: RootRoutes.PROFILE;
        readonly QNA: RootRoutes.QNA;
    };

    constructor(
        @Self() private readonly ngOnDestroy$: NgOnDestroy,
        readonly scannerService: ScannerService,
        private readonly router: Router
    ) {}

    ngOnInit() {
        this.routes = { ...RootRoutes };
        this.scannerService.scanButtonDisplay$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((next) => (this.isMainTabs = next));
    }

    openScan() {
        this.scannerService.openModal();
    }

    updateInput(tabs: IonTabs) {
        if (tabs.getSelected() === 'orders') {
            if (!this.router.url.includes('order-detail')) {
                this.scannerService.checkOpenedTab();
            }
        } else {
            this.scannerService.switchScanButtonDisplay(false);
        }
    }
}
