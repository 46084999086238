import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { getLangName } from 'src/app/helpers/getLangName';

@Component({
    selector: 'app-language-change',
    templateUrl: './language-change.component.html',
    styleUrls: ['./language-change.component.scss'],
})
export class LanguageChangeComponent implements OnInit {
    @Input() langList: string[];

    constructor(private modalController: ModalController) {}

    ngOnInit() {}

    async closeModel(lang: string | null): Promise<void> {
        await this.modalController.dismiss(lang);
    }

    langTranslate(charCode: string): string {
        return getLangName(charCode);
    }
}
