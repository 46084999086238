export class CustomerAddressModel {
    customerAddress: string;

    get addressText(): string {
        const parsedData = JSON.parse(this.customerAddress);
        let data = parsedData;

        if (parsedData?.length > 0) {
            data = parsedData[0];
        }

        const mainData = {};

        for (const prop in data) {
            if (
                prop === 'customer_address'
                || prop === 'customer_street'
                || prop === 'customer_city'
                || prop === 'zip'
                || prop === 'city'
                || prop === 'customer_zip'
            ) {
                mainData[prop] = data[prop];
            }
        }

        return `${Object.values(mainData).join(', ')}`;
    }
}
