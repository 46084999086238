
    export const environment = {
       pwaModeEnabled: true,
       production: false,
       urlApiBase: "https://api.dev.2wdelivery.com/api",
       logRocketAppId: "",
       pusherAppKey: "z8o2wLck0bIUGut",
       pusherAppCluster: "mt1",
       wsHost: "ws.dev.2wdelivery.com",
       wsPort: 443,
       vapidPublicKey: "undefined"
    };
