import {
    MINUTES_DOWNTIME_FOR_CHECKING_PIN_CODE,
    NUMBER_PIN_CODE_ATTEMPTS,
} from 'src/app/constants';
import { Observable } from 'rxjs';

export interface IPinCodeModel {
    hash: string;
    expiredAt: number;
    inputAttempts: number;
    exhaustionDate: number;
}

export class PinCodeModel implements IPinCodeModel {
    get timeIsRunning(): boolean {
        return !!this.exhaustionDate;
    }

    get attemptsExhausted() {
        return !!this.inputAttempts;
    }

    constructor(
        public hash: string,
        public expiredAt = 0,
        public exhaustionDate: number | null = null,
        public inputAttempts = NUMBER_PIN_CODE_ATTEMPTS
    ) {}

    updateInputAttempts() {
        this.exhaustionDate = null;
        this.inputAttempts = NUMBER_PIN_CODE_ATTEMPTS;
    }

    decrementInputAttempts() {
        this.inputAttempts--;
    }

    updateExpiredAtTime() {
        const date = new Date();

        const expiredAt = new Date(
            date.setMinutes(
                date.getMinutes() + MINUTES_DOWNTIME_FOR_CHECKING_PIN_CODE,
                date.getSeconds(),
                date.getMilliseconds()
            )
        );

        this.expiredAt = expiredAt.getTime();
    }

    hasTimeExpired(): Observable<boolean> {
        return new Observable((subscriber) => {
            const currentTime = new Date().getTime();
            const pinCodeExpiredAtTimeParsed = new Date(
                this.expiredAt
            ).getTime();

            subscriber.next(currentTime > pinCodeExpiredAtTimeParsed);
        });
    }

    toDto() {
        return {
            hash: this.hash,
            expiredAt: this.expiredAt,
            inputAttempts: this.inputAttempts,
            exhaustionDate: this.exhaustionDate,
        };
    }
}
