export enum OrderStatusesTextEnum {
    /**
     * Принят, ожидает распределения
     */
    TO_ASSIGN = 'Accepted (Awaiting distribution)',
    /**
     * Назначен, ожидает курьера
     */
    COURIER_ASSIGN = 'Waiting to receive',
    /**
     * Покинул склад
     */
    IN_DELIVERY = 'In Progress',
    /**
     * Курьер в пути
     */
    ON_WAY = 'Active',
    /**
     * Отказ
     */
    REJECTED = 'Reject',
    /**
     * Доставлен
     */
    BUYOUT = 'Buyout',
    /**
     * Курьер передал деньги
     */
    PAID = 'Delivered (Cash on hand)',
    /**
     * Архив
     */
    ARCHIVE = 'Archive',
    UNDEFINED = 'Undefined',
}
