import { Inject, Injectable } from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    from,
    fromEvent,
    Observable,
    ObservedValueOf,
    of,
} from 'rxjs';
import { NavController } from '@ionic/angular';
import { DATA_USERS } from 'src/app/constants';
import { Storage } from '@capacitor/storage';
import { UserService } from 'src/app/services/user.service';
import { UserApiService } from 'src/app/api/user-api-service';
import { Md5 } from 'ts-md5/dist/md5';
import { PinCodeModel } from 'src/app/models/pin-code.model';
import { PinCodeNotCreatedError } from 'src/app/errors/pin-code-not-created.error';
import { PinCodeRoutes } from 'src/app/enums/routes';
import {
    distinctUntilChanged,
    map,
    startWith,
    switchMap,
    tap,
} from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { ScannerService } from 'src/app/services/scanner.service';

export enum InputRequiredEnum {
    REQUIRED,
    NO_NEED,
    PIN_CODE_DOES_NOT_EXIST,
}

@Injectable({
    providedIn: 'root',
})
export class PinCodeService {
    isInputRequired: BehaviorSubject<InputRequiredEnum> =
        new BehaviorSubject<InputRequiredEnum>(null);

    pinCode = '';
    pinCodeModel: PinCodeModel;

    get timeIsNotRunningOut(): boolean {
        return this.pinCodeModel && !this.pinCodeModel.timeIsRunning;
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private readonly userApiService: UserApiService,
        private readonly navCtrl: NavController,
        private readonly userService: UserService,
        private readonly authService: AuthService,
        private readonly scannerService: ScannerService
    ) {}

    static loadPinCodeModel(): Observable<Record<string, any>> {
        return from(Storage.get({ key: DATA_USERS })).pipe(
            map((res) => {
                if (res.value) {
                    return JSON.parse(res.value);
                } else {
                    return {};
                }
            })
        );
    }

    #updateExpiredAtTime$() {
        return this.isInputRequired.pipe(
            switchMap((val) => {
                if (val === InputRequiredEnum.NO_NEED) {
                    this.pinCodeModel.updateExpiredAtTime();

                    return this.saveInLocalStorage();
                }

                return of(null);
            })
        );
    }

    #closeScannerWhenLeavingPage$() {
        return this.scannerService.scanOpened.pipe(
            tap((res) => {
                if (res) {
                    this.scannerService.closeModal();
                }
            })
        );
    }

    #requestPinCode$() {
        return this.findOutInputRequired$().pipe(
            switchMap(() => this.isInputRequired),
            switchMap((res) => {
                if (res === InputRequiredEnum.REQUIRED) {
                    return this.openPinCodePage$();
                }

                return of(null);
            })
        );
    }

    pageVisibility$(): Observable<unknown> {
        return fromEvent(this.document, 'visibilitychange').pipe(
            startWith(0),
            map(() => this.document.visibilityState),
            switchMap((visibilityState) => {
                if (this.pinCodeModel && this.authService.loggedIn) {
                    if (visibilityState === 'visible') {
                        return this.#requestPinCode$();
                    }

                    if (visibilityState === 'hidden') {
                        return combineLatest([
                            this.#closeScannerWhenLeavingPage$(),
                            this.#updateExpiredAtTime$(),
                        ]);
                    }
                }

                return of(null);
            }),
            distinctUntilChanged()
        );
    }

    toHashPin(pinCode: string): string {
        return Md5.hashStr(`${this.userService.userId}|${pinCode}`);
    }

    createPinCode(pinCode: string) {
        const hashPin = this.toHashPin(pinCode);

        this.pinCodeModel = new PinCodeModel(hashPin);
        this.pinCodeModel.updateExpiredAtTime();

        return this.saveInLocalStorage();
    }

    saveInLocalStorage() {
        return PinCodeService.loadPinCodeModel().pipe(
            map((userData) => {
                userData[this.userService.userId].pinCode =
                    this.pinCodeModel.toDto();

                return userData;
            }),
            switchMap((userData) =>
                from(
                    Storage.set({
                        key: DATA_USERS,
                        value: JSON.stringify(userData),
                    })
                )
            )
        );
    }

    openPinCodePage$(): Observable<ObservedValueOf<Promise<boolean>>> {
        return from(this.navCtrl.navigateForward(PinCodeRoutes.PIN_CODE));
    }

    changePinCode$() {
        return from(
            this.navCtrl.navigateForward(
                PinCodeRoutes.PIN_CODE + '/' + PinCodeRoutes.CHANGE
            )
        );
    }

    findOutInputRequired$() {
        return this.pinCodeModel
            .hasTimeExpired()
            .pipe(
                tap((res) =>
                    this.isInputRequired.next(
                        res
                            ? InputRequiredEnum.REQUIRED
                            : InputRequiredEnum.NO_NEED
                    )
                )
            );
    }

    initPinCode$(): Observable<PinCodeModel | PinCodeNotCreatedError> {
        return PinCodeService.loadPinCodeModel().pipe(
            map((res) => {
                const pinCodeModel = res[this.userService.userId]?.pinCode;

                if (pinCodeModel) {
                    return new PinCodeModel(
                        pinCodeModel.hash,
                        pinCodeModel.expiredAt,
                        pinCodeModel.exhaustionDate,
                        pinCodeModel.inputAttempts
                    );
                } else {
                    return new PinCodeNotCreatedError();
                }
            }),
            tap((model) => {
                if (model instanceof PinCodeModel) {
                    this.pinCodeModel = model;
                }
            })
        );
    }

    deletePinCode(): Observable<void> {
        return PinCodeService.loadPinCodeModel().pipe(
            map((data) => {
                data[this.userService.userId].pinCode = null;

                this.pinCodeModel = null;

                return data;
            }),
            switchMap((res) =>
                from(
                    Storage.set({
                        key: DATA_USERS,
                        value: JSON.stringify(res),
                    })
                )
            )
        );
    }
}
