import { IProduct } from 'src/app/interfaces';

export class ProductModel {
    name: string;
    quantity: number;
    price: number;

    imgSrc =
        'https://stage.2wcall.com/uploads/products/ZN2mfnfVnID3VOytLZQTq4R_WSNcMv5I.jpg';

    get productId(): string {
        return String(this.id);
    }

    constructor(private id: number) {}

    static fromDto(data: IProduct): ProductModel {
        const model = new ProductModel(data.id);

        model.name = data.name;
        model.quantity = data.quantity;
        model.price = data.price;

        return model;
    }
}
