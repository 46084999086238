import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    EMPTY,
    forkJoin,
    NEVER,
    Observable,
    of,
    Subject,
    timer,
} from 'rxjs';
import { map, mapTo, startWith, switchMap, take, tap } from 'rxjs/operators';
import { calculateRemainingSecondsUntilTimerExpires } from 'src/app/helpers/calculateRemainingSecondsUntilTimerExpires';
import { SECONDS_TO_GO_BACK_BY__STATUS } from 'src/app/constants';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserService } from 'src/app/services/user.service';

type State = Record<
    number,
    {
        timer$: Observable<string>;
        tick$: Subject<number>;
        stopTimerClick$: Subject<any>;
    }
>;

@Injectable({
    providedIn: 'root',
})
export class OrderTimersService {
    orderStatusReturnTimers: BehaviorSubject<State> =
        new BehaviorSubject<State>({});

    constructor(
        private localStorageService: LocalStorageService,
        private userService: UserService
    ) {}

    #stopTimerByOrderId(orderId: number): void {
        this.orderStatusReturnTimers.value[orderId].stopTimerClick$.next();
    }

    #deleteTimerByOrderId(orderId: number): void {
        delete this.orderStatusReturnTimers.value[orderId];
    }

    #addTimer(orderId: string, changeOfStatusDate: number): void {
        const remainingSeconds = calculateRemainingSecondsUntilTimerExpires(
            SECONDS_TO_GO_BACK_BY__STATUS,
            changeOfStatusDate
        );

        const tick$ = new Subject<number>();
        const stopTimerClick$ = new Subject<{ count: boolean }>();

        const stopTimerClickObservable$ = stopTimerClick$.asObservable();

        const event$ = stopTimerClickObservable$.pipe(mapTo({ count: false }));

        const timer$ = event$.pipe(
            startWith({ count: true }),
            switchMap(({ count }) => {
                if (count) {
                    return this.backTimer$(remainingSeconds).pipe(
                        switchMap((v) => {
                            if (v > 0) {
                                tick$.next(v);
                                return NEVER;
                            } else {
                                return this.timerClearing$(Number(orderId));
                            }
                        })
                    );
                }

                tick$.next(0);

                return NEVER;
            })
        );

        this.orderStatusReturnTimers.next(
            Object.assign(this.orderStatusReturnTimers.value, {
                [orderId]: { tick$, timer$, stopTimerClick$ },
            })
        );
    }

    getOrderStatusReturnTimers$(): Observable<State> {
        return this.orderStatusReturnTimers.asObservable();
    }

    getTickByOrderId$(orderId: number): Observable<number> | null {
        if (!this.orderStatusReturnTimers.value[orderId]) {
            return null;
        }

        return this.orderStatusReturnTimers.value[orderId].tick$;
    }

    timerClearing$(orderId: number): Observable<any> {
        return this.localStorageService
            .updateChangeOfStatusDateOrderById$(
                [orderId],
                this.userService.userId,
                null
            )
            .pipe(
                tap(() => {
                    this.#stopTimerByOrderId(orderId);
                    this.#deleteTimerByOrderId(orderId);
                })
            );
    }

    backTimer$(remainingSeconds: number, interval = 1000): Observable<number> {
        return timer(0, interval).pipe(
            take(remainingSeconds + 1),
            map((i) => remainingSeconds - i)
        );
    }

    addTimerHandler$(): Observable<any> {
        const orderStatusReturnTimers$ = this.getOrderStatusReturnTimers$();

        const event$ = orderStatusReturnTimers$.pipe(map((res) => res));

        return event$.pipe(
            map((res) => Object.values(res).map((x) => x.timer$)),
            switchMap((res: Observable<string>[]) => forkJoin(res))
        );
    }

    createdTimers$(orders: Record<string, any>): Observable<any> {
        const pastOrderTimersIds = [];

        Object.entries(orders).forEach(([key, value]) => {
            if (value.changeOfStatusDate !== null) {
                const remainingSeconds =
                    calculateRemainingSecondsUntilTimerExpires(
                        SECONDS_TO_GO_BACK_BY__STATUS,
                        value.changeOfStatusDate
                    );

                if (
                    remainingSeconds > SECONDS_TO_GO_BACK_BY__STATUS ||
                    remainingSeconds <= 0
                ) {
                    pastOrderTimersIds.push(key);

                    return;
                }

                this.#addTimer(key, value.changeOfStatusDate);
            }
        });

        if (pastOrderTimersIds.length) {
            return this.localStorageService.updateChangeOfStatusDateOrderById$(
                pastOrderTimersIds,
                this.userService.userId,
                null
            );
        }

        return of(null);
    }
}
