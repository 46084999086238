import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserApiService } from 'src/app/api/user-api-service';
import { GetResult, Storage } from '@capacitor/storage';
import { ACCESS_TOKEN } from 'src/app/constants';
import { ToastService } from 'src/app/services/toast.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    isAuthenticated: BehaviorSubject<boolean | null> = new BehaviorSubject<
        boolean | null
    >(null);
    token = '';

    get loggedIn(): boolean {
        return !!this.isAuthenticated.value;
    }

    constructor(
        private readonly userApiService: UserApiService,
        private readonly toastService: ToastService
    ) {}

    async loadToken(): Promise<void> {
        const token = await this.getTokenInStorage();

        this.token = token?.value || '';

        const isAuthenticated = !!this.token.length;

        this.isAuthenticated.next(isAuthenticated);
    }

    login(credentials: { email: string; password: string }): Observable<void> {
        return this.userApiService
            .login(credentials.email, credentials.password)
            .pipe(
                map((data) => data.token),
                switchMap((token) => from(this.putTokenInStorage(token)))
            );
    }

    #logout(): Observable<void> {
        return this.userApiService.logout().pipe(
            map((data) => data.message),
            switchMap(() => from(this.removeTokenInStorage()))
        );
    }

    forgotPassword(credentials: { email: string }): Observable<any> {
        return this.userApiService.forgotPassword(credentials.email);
    }

    resetCheck(token: string, email: string): Observable<void> {
        return this.userApiService.resetCheck(token, email);
    }

    resetPassword(
        token: string,
        email: string,
        newPassword: string
    ): Observable<void> {
        return this.userApiService.resetPassword(token, email, newPassword);
    }

    async putTokenInStorage(value: string): Promise<void> {
        await Storage.set({ key: ACCESS_TOKEN, value });
        await this.loadToken();
    }

    async getTokenInStorage(): Promise<GetResult> {
        return Storage.get({ key: ACCESS_TOKEN });
    }

    async removeTokenInStorage(): Promise<void> {
        await Storage.remove({ key: ACCESS_TOKEN });
        await this.loadToken();
    }

    signOut(): Observable<Observable<never> | void> {
        return this.#logout().pipe(
            catchError((e) => this.toastService.showDangerToast$(e))
        );
    }
}
