import { Injectable } from '@angular/core';
import { filter, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PinCodeModel } from 'src/app/models/pin-code.model';
import { PinCodeNotCreatedError } from 'src/app/errors/pin-code-not-created.error';
import {
    InputRequiredEnum,
    PinCodeService,
} from 'src/app/services/pin-code.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthEventService {
    constructor(
        private readonly authService: AuthService,
        private readonly pinCodeService: PinCodeService,
        private readonly userService: UserService
    ) {}

    public isAuthenticated$(): Observable<any> {
        return this.authService.isAuthenticated.pipe(
            filter((val) => val !== null),
            switchMap((isAuthenticated) =>
                isAuthenticated ? this.userService.initUser$() : of(null)
            ),
            switchMap((userRes) =>
                userRes ? this.pinCodeService.initPinCode$() : of(null)
            ),
            switchMap((model) => {
                if (model === null && this.pinCodeService.timeIsNotRunningOut) {
                    this.pinCodeService.pinCodeModel.expiredAt = 0;

                    return this.pinCodeService.saveInLocalStorage();
                }

                if (model instanceof PinCodeModel) {
                    return this.pinCodeService
                        .findOutInputRequired$()
                        .pipe(
                            switchMap(() =>
                                this.pinCodeService.openPinCodePage$()
                            )
                        );
                }

                if (model instanceof PinCodeNotCreatedError) {
                    this.pinCodeService.isInputRequired.next(
                        InputRequiredEnum.PIN_CODE_DOES_NOT_EXIST
                    );

                    return this.pinCodeService.openPinCodePage$();
                }

                return of(model);
            })
        );
    }
}
