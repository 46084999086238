import { Injectable } from '@angular/core';
import { FinanceApiService } from 'src/app/api/finance-api-service';
import { FinanceModel } from 'src/app/models/finance.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FinanceGroupModel } from 'src/app/models/finance-group.model';
import { OrderStatusesEnum } from 'src/app/enums/order-statuses.enum';
import { StatusesFlowService } from 'src/app/services/statuses-flow.service';
import { UserService } from 'src/app/services/user.service';
import { OrderService } from 'src/app/services/order.service';

@Injectable({
    providedIn: 'root',
})
export class FinanceService {
    #financeList: BehaviorSubject<FinanceGroupModel[]> = new BehaviorSubject<
        FinanceGroupModel[]
    >([]);

    get isEmptyList() {
        return this.#financeList.value.length === 0;
    }

    constructor(
        private readonly financeApiService: FinanceApiService,
        private readonly statusesFlowService: StatusesFlowService,
        private readonly userService: UserService,
        private readonly orderService: OrderService
    ) {}

    getList$() {
        return this.#financeList.asObservable();
    }

    getFilteredOrders(selectedDate: string): Observable<FinanceModel[]> {
        return this.financeApiService.fetchFinanceList().pipe(
            map((items) =>
                items
                    .filter(
                        (x) =>
                            x.delivery_date === selectedDate &&
                            (x.order_status === OrderStatusesEnum.PAID ||
                                x.order_status === OrderStatusesEnum.BUYOUT)
                    )
                    .map((x) => {
                        const order = this.orderService.allOrders.find(
                            (a) => x.id === a.orderId
                        );

                        const financeModel = FinanceModel.fromDto(order.id, x);

                        financeModel.availableStatuses =
                            this.statusesFlowService.statusesFlowModel.getAvailableStatusesForTransition(
                                financeModel.currentStatus,
                                this.userService.currentRole
                            );

                        return financeModel;
                    })
            )
        );
    }

    getFinanceGroupList$(): Observable<FinanceGroupModel[]> {
        return this.financeApiService.fetchFinanceGroup().pipe(
            map((data) => data.map((x) => FinanceGroupModel.fromDto(x))),
            tap((res) => this.#financeList.next(res))
        );
    }
}
