import {OrderModel} from 'src/app/models/order.model';
import {Mixin} from 'src/app/mixins/mixin.decorator';
import {ResponseStatusEnum, ResponseStatusModel} from 'src/app/models/response-status.model';


interface State {
    count: boolean;
    value: number;
    orders: OrderModel[];
    remainingSeconds: number;
}


@Mixin(ResponseStatusModel)
export class ChangeStatusCounterModel implements State, ResponseStatusModel {
    resStatus: ResponseStatusEnum;

    constructor(
        public count = false,
        public value = 0,
        public orders: OrderModel[] = [],
        public remainingSeconds = 0,
    ) {}
}
