import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { OrderModel } from 'src/app/models/order.model';
import { ResponseStatusEnum } from 'src/app/models/response-status.model';

@Component({
    selector: 'app-toast-result-scan',
    templateUrl: './toast-result-scan.component.html',
    styleUrls: ['./toast-result-scan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastResultScanComponent implements OnChanges {
    @Input() orderModel: OrderModel;
    @Input() timerSeconds = 0;
    @Input() resStatus: ResponseStatusEnum;

    @Output() dismissButton: EventEmitter<'cancel' | 'ok'> = new EventEmitter<
        'cancel' | 'ok'
    >();
    @Output() clickHandlerOrderId: EventEmitter<OrderModel> =
        new EventEmitter<OrderModel>();

    iconName = '';

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.resStatus) {
            if (changes.resStatus.currentValue === ResponseStatusEnum.SUCCESS) {
                this.iconName = 'checkmark-outline';
            } else if (
                changes.resStatus.currentValue === ResponseStatusEnum.ERROR
            ) {
                this.iconName = 'alert-outline';
            } else {
                this.iconName = '';
            }

            this.cdr.detectChanges();
        }
    }

    dismiss() {
        this.dismissButton.emit();
    }

    toOrderById() {
        this.clickHandlerOrderId.emit(this.orderModel);
    }
}
