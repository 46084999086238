import { ScannerService } from 'src/app/services/scanner.service';
import { NgOnDestroy } from './../../services/ng-on-destroy.service';
import { takeUntil } from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Self,
} from '@angular/core';
import { OrderStatusesTextEnum } from 'src/app/enums/order-statuses-text.enum';
import { Subscription } from 'rxjs';
import { ChangeStatusOrderService } from 'src/app/services/change-status-order.service';
import { OrderModel } from 'src/app/models/order.model';

@Component({
    selector: 'app-order-status-tabs',
    templateUrl: './order-status-tabs.component.html',
    styleUrls: ['./order-status-tabs.component.scss'],
    providers: [NgOnDestroy],
})
export class OrderStatusTabsComponent implements OnInit {
    @Input() availableOrderStatuses: OrderStatusesTextEnum[];
    @Input() selectedStatus: OrderStatusesTextEnum;
    @Input() orders: OrderModel[];

    @Output() changeStatus: EventEmitter<OrderStatusesTextEnum> =
        new EventEmitter<OrderStatusesTextEnum>();

    initStatus: string;

    notifierSub: Subscription =
        this.changeStatusOrderService.changeStatusNotifier
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe(() => {
                this.changeStatus.emit(this.availableOrderStatuses[0]);
            });

    constructor(
        @Self() private readonly ngOnDestroy$: NgOnDestroy,
        private changeStatusOrderService: ChangeStatusOrderService,
        private scannerService: ScannerService
    ) {}

    ngOnInit() {
        this.scannerService.openedTab$.subscribe((next) => {
            if (next === 'In Progress') {
                this.scannerService.switchScanButtonDisplay(true);
            } else {
                this.scannerService.switchScanButtonDisplay(false);
            }
        });
        const selectedStatus = this.availableOrderStatuses[0];
        this.selectedStatus = selectedStatus;
        this.initStatus = this.selectedStatus;
        this.scannerService.openedTab.next(this.selectedStatus);
        this.changeStatus.emit(selectedStatus);
    }

    onChangedStatus(e: any) {
        this.scannerService.openedTab.next(e.detail.value);
        this.changeStatus.emit(e.detail.value);
    }

    ordersCount(statusText: OrderStatusesTextEnum) {
        return this.orders.filter((ord) => ord.statusText === statusText)
            .length;
    }
}
