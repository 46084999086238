import { Component, Input, OnInit, Self } from '@angular/core';
import { OrderStatusesEnum } from 'src/app/enums/order-statuses.enum';
import { OrderStatusesTextEnum } from 'src/app/enums/order-statuses-text.enum';
import { map, takeUntil, tap } from 'rxjs/operators';
import { OrderService } from 'src/app/services/order.service';
import { NgOnDestroy } from 'src/app/services/ng-on-destroy.service';
import { OrderTimersService } from 'src/app/services/order-timers.service';
import { getMinutesAndSeconds } from 'src/app/helpers/getMinutesAndSeconds';
import { forkJoin, NEVER, Observable } from 'rxjs';

@Component({
    selector: 'app-order-card',
    templateUrl: './order-card.component.html',
    styleUrls: ['./order-card.component.scss'],
    providers: [NgOnDestroy],
})
export class OrderCardComponent implements OnInit {
    @Input() status: OrderStatusesEnum;
    @Input() previousStatusAvailable: OrderStatusesEnum;
    @Input() statusText: OrderStatusesTextEnum;
    @Input() orderId: number;
    @Input() totalPrice: number;
    @Input() currencyCode: string;
    @Input() addressText: string;
    @Input() deliveryDesiredDate: string;
    @Input() deliveryDate: string;

    tickToGoBackStatus = '';
    isShowNotifyReverseStatusChange = false;

    constructor(
        @Self() private ngOnDestroy$: NgOnDestroy,
        private orderService: OrderService,
        private orderTimersService: OrderTimersService
    ) {}

    ngOnInit() {
        const arr$ = [this.#initTimer()];

        forkJoin(arr$).pipe(takeUntil(this.ngOnDestroy$)).subscribe();
    }

    #initTimer(): Observable<string> {
        const tickTimer$ = this.orderTimersService.getTickByOrderId$(
            this.orderId
        );

        if (this.previousStatusAvailable && tickTimer$) {
            const event$ = tickTimer$.pipe(map((sec) => sec));

            return event$.pipe(
                tap(
                    (sec) => (this.isShowNotifyReverseStatusChange = sec !== 0)
                ),
                map((sec) => {
                    const { minutes, seconds } = getMinutesAndSeconds(sec);

                    return `${minutes}:${
                        seconds >= 10 ? seconds : `0${seconds}`
                    }`;
                }),
                tap((value) => {
                    this.tickToGoBackStatus = value;
                })
            );
        }

        return NEVER;
    }
}
