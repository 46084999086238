import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { CanLoad } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { AuthRoutes } from 'src/app/enums/routes';
import { NavController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanLoad {
    constructor(
        private authService: AuthService,
        private navCtrl: NavController
    ) {}

    canLoad(): Observable<boolean> {
        return this.authService.isAuthenticated.pipe(
            filter((val) => val !== null),
            take(1),
            map((isAuthenticated) => {
                if (isAuthenticated) {
                    return true;
                } else {
                    void this.navCtrl.navigateForward([AuthRoutes.LOGIN], {
                        replaceUrl: true,
                    });
                }
            })
        );
    }
}
