import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {
    InputRequiredEnum,
    PinCodeService,
} from 'src/app/services/pin-code.service';
import { NavController } from '@ionic/angular';
import { filter, map } from 'rxjs/operators';
import { RootRoutes } from 'src/app/enums/routes';

@Injectable({
    providedIn: 'root',
})
export class SecureInnerPinCodeGuard implements CanLoad {
    constructor(
        private readonly pinCodeService: PinCodeService,
        private readonly navCtrl: NavController
    ) {}

    canLoad():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.pinCodeService.isInputRequired.pipe(
            filter((val) => val !== null),
            map((inputRequired) => {
                switch (inputRequired) {
                    case InputRequiredEnum.NO_NEED: {
                        void this.navCtrl.navigateForward(
                            `${RootRoutes.ROOT}`,
                            { replaceUrl: true }
                        );

                        break;
                    }

                    case InputRequiredEnum.REQUIRED:
                    case InputRequiredEnum.PIN_CODE_DOES_NOT_EXIST: {
                        return true;
                    }

                    default: {
                        return false;
                    }
                }
            })
        );
    }
}
