import { IUser, IUserStatistic } from 'src/app/interfaces';
import { UserStatisticModel } from 'src/app/models/user-statistic.model';

export class UserModel {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    countryId: number;
    organizationId: number;
    role: string;
    isActive: boolean;
    createdAt: string;
    lastActivity: string;
    supportPhone: string;
    manualStatusChange: boolean;

    statistic: UserStatisticModel;

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    constructor(public id: number) {}

    public static fromDto(
        data: IUser,
        userStatistic: IUserStatistic
    ): UserModel {
        const model = new UserModel(data.id);

        model.firstName = data.first_name || '';
        model.lastName = data.last_name || '';
        model.email = data.email;
        model.phone = data.phone;
        model.countryId = data.country_id;
        model.organizationId = data.organization_id;
        model.role = data.role;
        model.isActive = data.is_active;
        model.createdAt = data.created_at;
        model.lastActivity = data.last_activity;
        model.supportPhone = data.help_phone;
        model.manualStatusChange = Boolean(data?.manual_status_change);

        model.statistic = UserStatisticModel.fromDto(userStatistic);

        return model;
    }
}
