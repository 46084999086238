import {NgModule} from '@angular/core';
import {CounterDirective} from './counter/counter.directive';
import {CommonModule} from '@angular/common';
import {DataCyDirective} from './data-cy/data-cy.directive';


@NgModule({
    declarations: [
        CounterDirective,
        DataCyDirective,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        CounterDirective,
        DataCyDirective,
    ],
})
export class DirectivesModule {}
