import {Injectable, NgZone} from '@angular/core';
import * as LogRocket from 'logrocket';
import {environment} from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
})
export class LogrocketService {
    constructor(private ngZone: NgZone) {}

    identifyUser(userId: number, firstName: string, lastName: string, username: string): void {
        if (!environment.logRocketAppId.length) {
            return;
        }

        this.ngZone.runOutsideAngular(() => {
            LogRocket.identify(
                `{login: ${username}, id: ${String(userId)}}`,
                {
                    name: `${firstName} ${lastName}`,
                    username,
                });
        });
    }

    init(): void {
        if (!environment.logRocketAppId.length) {
            return;
        }

        // eslint-disable-next-line no-console
        console.info(`init LogRocket on ${environment.logRocketAppId}`);

        this.ngZone.runOutsideAngular(() => {
            LogRocket.init(environment.logRocketAppId);
        });
    }
}
