import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
    selector: 'app-bottom-onboard-toolbar',
    templateUrl: './bottom-onboard-toolbar.component.html',
    styleUrls: ['./bottom-onboard-toolbar.component.scss'],
})
export class BottomOnboardToolbarComponent implements OnInit {
    @Output() next: EventEmitter<any> = new EventEmitter();
    @Output() prev: EventEmitter<any> = new EventEmitter();

    @Input() activeIndex: number;

    constructor() { }

    ngOnInit() {}

    nextHandler() {
        this.next.emit();
    }

    prevHandler() {
        this.prev.emit();
    }
}
