/* eslint-disable @typescript-eslint/naming-convention */
export const ACCESS_TOKEN = 'accessToken';
export const STORAGE_LOCALIZE = 'localize';
export const TRANSLATIONS_ADDED_PREVIOUSLY = 'translations_added_previously';
export const CALL_ATTEMPTS = 'call_attempts';
export const CURRENT_LANG = 'current_lang';
export const PASSED_START_PAGE = 'passed_start_page';
export const PIN_CODE_EXPIRED_AT = 'pin_code_expired_at';
export const DATA_USERS = 'data_users';
export const PIN_CODE_INPUT_REQUIRED = 'pin_code_input_required';
export const PIN_CODE_LOCK_SECONDS = 900;
export const NUMBER_PIN_CODE_ATTEMPTS = 5;
export const MINUTES_DOWNTIME_FOR_CHECKING_PIN_CODE = 3;
export const SECONDS_TO_GO_BACK_BY__STATUS = 180;
export const SPEED_TEST_512KB =
    'https://raw.githubusercontent.com/jrquick17/ng-speed-test/02c59e4afde67c35a5ba74014b91d44b33c0b3fe/demo/src/assets/500kb.jpg';
