import { environment } from 'src/environments/environment';
import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'data-cy',
})
export class DataCyDirective {
    constructor(protected el: ElementRef, protected renderer: Renderer2) {
        if (environment.production) {
            renderer.removeAttribute(el.nativeElement, 'data-cy');
        }
    }
}
