import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {AuthGuard} from 'src/app/guards/auth.guard';
import {SecureInnerPagesGuard} from 'src/app/guards/secure-inner-pages.guard';
import {AuthRoutes, PinCodeRoutes, RootRoutes} from 'src/app/enums/routes';
import {PinCodeGuard} from 'src/app/guards/pin-code.guard';
import {SecureInnerPinCodeGuard} from 'src/app/guards/secure-inner-pin-code.guard';


const routes: Routes = [
    {
        path: AuthRoutes.AUTH,
        loadChildren: async () => {
            const { AuthModule } = await import('src/app/pages/auth/auth.module');

            return AuthModule;
        },
        canLoad: [SecureInnerPagesGuard],
    },
    {
        path: PinCodeRoutes.PIN_CODE,
        loadChildren: async () => {
            const { PinCodeModule } = await import('src/app/pages/pin-code/pin-code.module');

            return PinCodeModule;
        },
        canLoad: [AuthGuard, SecureInnerPinCodeGuard],
    },
    {
        path: RootRoutes.ROOT,
        loadChildren: async () => {
            const { RootModule } = await import('src/app/pages/root/root.module');

            return RootModule;
        },
        canLoad: [AuthGuard, PinCodeGuard],
    },
    {
        path: '',
        redirectTo: AuthRoutes.AUTH,
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: AuthRoutes.AUTH,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            { preloadingStrategy: PreloadAllModules },
        ),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
