import { IFinanceQuery } from 'src/app/interfaces';
import { OrderStatusesEnum } from 'src/app/enums/order-statuses.enum';
import { OrderStatusesTextEnum } from 'src/app/enums/order-statuses-text.enum';
import { Mixin } from 'src/app/mixins/mixin.decorator';
import { CustomerAddressModel } from 'src/app/models/customer-address.model';
import { AvailableStatusesModel } from 'src/app/models/available-statuses.model';

@Mixin(CustomerAddressModel, AvailableStatusesModel)
export class FinanceModel
    implements CustomerAddressModel, AvailableStatusesModel
{
    orderPrice: number;
    deliveryPrice: number;
    deliveryDate: string;
    deliveryDesiredDate: string;
    currencyCode: string;
    customerAddress: string;
    addressText: string;
    orderId: number;
    availableStatuses: OrderStatusesEnum[] = [];
    currentStatus: OrderStatusesEnum;
    nextStatusAvailable: OrderStatusesEnum | undefined;
    previousStatusAvailable: OrderStatusesEnum | undefined;
    isOrderOnWay: boolean;
    statusText: OrderStatusesTextEnum;
    previousStatusText: OrderStatusesTextEnum;

    get totalPrice(): number {
        // Rounding money
        return +(this.orderPrice + this.deliveryPrice + Number.EPSILON).toFixed(
            2
        );
    }

    constructor(public id: number) {}

    static fromDto(id: number, data: IFinanceQuery): FinanceModel {
        const model = new FinanceModel(id);

        model.orderPrice = data.order_price;
        model.deliveryPrice = data.delivery_price;
        model.deliveryDate = data.delivery_date;
        model.currentStatus = data.order_status;
        model.deliveryDesiredDate = data.delivery_desired_date;
        model.currencyCode = data.currency_code;
        model.customerAddress = data.customer_address;
        model.orderId = data.id;

        return model;
    }
}
