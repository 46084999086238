export class ImplicitObjectMixin {
    static forObject(pThis): ImplicitObjectMixin {
        return new Wrapper(pThis);
    }

    getProp(propName: string) {
        return (this as any)[propName];
    }

    setProp(propName: string, value: any) {
        (this as any)[propName] = value;
    }

    incrementProp(propName: string) {
        const pThis: any = this;

        if (typeof pThis[propName] !== 'number') {
            this[propName] = 0;
        }

        pThis[propName]++;
    }

    decrementProp(propName: string) {
        const pThis: any = this;

        if (typeof pThis[propName] !== 'number' ||
            pThis[propName] < 1) {
            pThis[propName] = 0;
        }
        else {
            pThis[propName]--;
        }
    }
}

class Wrapper implements ImplicitObjectMixin {
    #instance: ImplicitObjectMixin;

    constructor(private readonly pThis) {
        this.#instance = new ImplicitObjectMixin();
    }

    getProp(propName: string) {
        return this.#instance.getProp.call(this.pThis, propName);
    }

    setProp(propName: string, value: any) {
        return this.#instance.setProp.call(this.pThis, propName, value);
    }

    incrementProp(propName: string): void {
        return this.#instance.incrementProp.call(this.pThis, propName);
    }

    decrementProp(propName: string): void {
        return this.#instance.decrementProp.call(this.pThis, propName);
    }
}
