import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CURRENT_LANG} from 'src/app/constants';
import {ModalController} from '@ionic/angular';
import {LanguageChangeComponent} from 'src/app/modals/language-change/language-change.component';
import {I18nApiService} from 'src/app/api/i18n-api-service';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, from, Observable, ObservableInput, ObservedValueOf} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class LangChangeService {
    currentLang: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    #langList: string[];

    constructor(
        private readonly translateService: TranslateService,
        private readonly modalController: ModalController,
        private readonly i18NApiService: I18nApiService,
    ) {}

    currentLang$() {
        return this.currentLang.pipe(
            filter((val) => val !== null),
            tap((currentLang: string) => {
                this.translateService.use(currentLang);
            }),
        );
    }

    onLangChange$(): Observable<{ readonly lang?: any }> {
        return this.translateService.onLangChange.pipe(
            tap(({ lang }) => localStorage.setItem(CURRENT_LANG, lang)),
        );
    }

    getLangList$(): Observable<any> {
        return this.i18NApiService.getLangList().pipe(
            map(data => data.map(x => x.char_code)),
            tap((value) => {
                this.#langList = value;
            }),
        );
    }

    initLang() {
        const currentLangInLocalStorage = localStorage.getItem(CURRENT_LANG);

        if (currentLangInLocalStorage) {
            this.currentLang.next(currentLangInLocalStorage);
        } else {
            const browserLang = this.translateService.getBrowserLang();

            const currentLang = browserLang.match(/en|es|ru/) ? browserLang : 'en';

            this.currentLang.next(currentLang);
        }
    }

    openLangChangeModal$(): Observable<Observable<ObservedValueOf<any>> extends ObservableInput<infer T> ? T : never> {
        const modalOpts = {
            component: LanguageChangeComponent,
            componentProps: { langList: this.#langList },
            backdropDismiss: false,
        };

        return from(this.modalController.create(modalOpts))
            .pipe(
                tap((modal) => {
                    modal.onDidDismiss().then((modalData) => {
                        if (modalData.data !== null) {
                            this.translateService.use(modalData.data);
                        }
                    });
                }),
                switchMap((modal) => from(modal.present())),
            );
    }
}
