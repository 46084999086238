import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    IOrderStatusUpdate,
    IOrderWrapper,
    IUserStatistic,
} from 'src/app/interfaces';
import { pluck, retry, take } from 'rxjs/operators';
import { OrderStatusesEnum } from 'src/app/enums/order-statuses.enum';

export type WithResponse<T> = { readonly data: T };

export type TCourierOrdersFilters = {
    /**
     * Фильтр по Id заказа
     */
    id?: number;
    /**
     * Фильтр по трек коду
     */
    track?: string;
    /**
     * Фильтр по телефону
     */
    customer_phone?: string;
    /**
     * Фильтр по имени (частичное совпадение)
     */
    customer_full_name?: string;
    /**
     * Фильтр по дате отправки (от)
     */
    delivery_date_from?: string;
    /**
     * Фильтр по дате отправки (до)
     */
    delivery_date_to?: string;
    status?: OrderStatusesEnum;
};

@Injectable({
    providedIn: 'root',
})
export class OrderApiService {
    constructor(private readonly http: HttpClient) {}

    getCourierOrderList(
        courierOrdersFilters?: TCourierOrdersFilters
    ): Observable<IOrderWrapper[]> {
        let params = new HttpParams();

        if (courierOrdersFilters) {
            for (const [key, value] of Object.entries(courierOrdersFilters)) {
                params = params.set(key, value);
            }
        }

        const response$ = this.http.get<WithResponse<IOrderWrapper[]>>(
            '/courier-order',
            { params }
        );

        return response$.pipe(retry(3), pluck('data'));
    }

    getCourierOrderById(orderOd: number): Observable<IOrderWrapper> {
        return this.http
            .get<WithResponse<IOrderWrapper>>(`/courier-order/${orderOd}`)
            .pipe(pluck('data'));
    }

    changeOrderStatus(
        orderId: number,
        statusId: number,
        orderSubStatus?: number
    ): Observable<IOrderStatusUpdate> {
        const body = orderSubStatus
            ? {
                  sub_status: orderSubStatus,
              }
            : {};
        return this.http
            .post(`/order/status/${orderId}/${statusId}`, body)
            .pipe(pluck('data'));
    }

    getStatistic(): Observable<IUserStatistic> {
        return this.http
            .get<WithResponse<IUserStatistic>>('/order/statistic')
            .pipe(pluck('data'));
    }

    getSubStatuses(): Observable<any> {
        return this.http.get('/order-status').pipe(take(1), pluck('data'));
    }
}
