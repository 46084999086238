import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ScannerService {
    scanOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    scanSwitch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    scanButtonDisplay: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    scanButtonDisplay$ = this.scanButtonDisplay.asObservable();

    openedTab: BehaviorSubject<string> = new BehaviorSubject('');
    openedTab$ = this.openedTab.asObservable();

    closeModal() {
        this.scanOpened.next(false);
    }

    openModal() {
        this.scanOpened.next(true);
    }

    switchScanButtonDisplay(switcher: boolean) {
        this.scanButtonDisplay.next(switcher);
    }

    checkOpenedTab() {
        if (this.openedTab.value === 'In Progress') {
            this.switchScanButtonDisplay(true);
        } else {
            this.openedTab.next(this.openedTab.value);
        }
    }
}
