import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthRoutes } from 'src/app/enums/routes';
import { TranslateService } from '@ngx-translate/core';
import { CURRENT_LANG } from 'src/app/constants';
import { NavController } from '@ionic/angular';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private readonly authService: AuthService,
        private readonly navCtrl: NavController,
        private readonly translateService: TranslateService
    ) {}

    #getCurrentLang(): string {
        const currentLangInLocalStorage = localStorage.getItem(CURRENT_LANG);

        if (currentLangInLocalStorage) {
            return currentLangInLocalStorage;
        } else {
            const browserLang = this.translateService.getBrowserLang();
            return browserLang.match(/en|es|ru/) ? browserLang : 'en';
        }
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        req = req.clone({
            url: `${environment.urlApiBase}${req.url}`,
            setHeaders: {
                'X-locale': this.#getCurrentLang(),
            },
        });

        if (this.authService.token.length) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authService.token}`,
                },
            });
        }

        return next
            .handle(req)
            .pipe(catchError(this.#errorHandler$.bind(this)));
    }

    #errorHandler$(error: HttpErrorResponse): Observable<any> {
        switch (error.status) {
            case 0: {
                const msg =
                    error.message ||
                    `Unexpected error, try again. Status error: ${error.status}`;
                return throwError(msg);
            }

            case 401: {
                return from(this.authService.removeTokenInStorage()).pipe(
                    switchMap(() =>
                        from(this.navCtrl.navigateForward(AuthRoutes.LOGIN))
                    ),
                    switchMap(() => {
                        const msg =
                            error.error.message ||
                            `Status error: ${error.status}`;
                        return throwError(msg);
                    })
                );
            }

            case 403: {
                const msg =
                    error.error.message || `Status error: ${error.status}`;
                return throwError(msg);
            }

            case 422: {
                const msg =
                    error.error.errors[0] ||
                    error.message ||
                    `Status error: ${error.status}`;
                return throwError({ message: msg, status: error.status });
            }

            default: {
                return throwError(error.message || 'Unknown Error');
            }
        }
    }
}
