import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
    selector: 'app-top-onboard-toolbar',
    templateUrl: './top-onboard-toolbar.component.html',
    styleUrls: ['./top-onboard-toolbar.component.scss'],
})
export class TopOnboardToolbarComponent implements OnInit {
    @Output() skip: EventEmitter<any> = new EventEmitter();

    @Input() activeIndex: number;

    get currentSlide() {
        return `${this.activeIndex + 1}/4`;
    };

    constructor() { }

    ngOnInit() {}

    start() {
        this.skip.emit();
    }
}
