import { StatusLinkModel } from 'src/app/models/status-link.model';
import { IFlow } from 'src/app/interfaces';
import { OrderStatusesEnum } from 'src/app/enums/order-statuses.enum';

export class StatusesFlowModel {
    links: Record<string, StatusLinkModel>;

    constructor(public id: number) {}

    public static fromDto(data: IFlow, currentRole: string): StatusesFlowModel {
        const model = new StatusesFlowModel(data.id);

        model.links = data.links.reduce<Record<string, StatusLinkModel>>(
            (acc, item) => {
                if (!acc[item.status_from]) {
                    acc[item.status_from] = StatusLinkModel.fromDto([], []);
                }

                if (item.roles.includes(currentRole)) {
                    acc[item.status_from].possibleStatusesForTransition.push(
                        item.status_to
                    );
                    acc[item.status_from].accessRoles = item.roles;
                }

                return acc;
            },
            {}
        );

        return model;
    }

    getAvailableStatusesForTransition(
        status: OrderStatusesEnum,
        currentRole: string
    ): OrderStatusesEnum[] {
        const link = this.links[status];

        return link?.accessRoles.includes(currentRole)
            ? link.possibleStatusesForTransition
            : [];
    }
}
