import { OrderStatusesEnum } from 'src/app/enums/order-statuses.enum';

export class StatusLinkModel {
    possibleStatusesForTransition: OrderStatusesEnum[];
    accessRoles: string[];

    public static fromDto(
        possibleStatuses: OrderStatusesEnum[],
        roles: string[]
    ): StatusLinkModel {
        const model = new StatusLinkModel();

        model.possibleStatusesForTransition = possibleStatuses;
        model.accessRoles = roles;

        return model;
    }
}
