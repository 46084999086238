import { Injectable } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { DatePipe } from '@angular/common';
import { IndexedDbService } from './indexeddb-service';
import { OrderModel } from 'src/app/models/order.model';
import { IDateObject } from '../interfaces';
import { OrderStatusesTextEnum } from '../enums/order-statuses-text.enum';

@Injectable({
    providedIn: 'root',
})
export class PendingDateService {
    public pendingDate: number;
    public completeFlag: boolean;

    constructor(
        public readonly orderService: OrderService,
        private indexedDbService: IndexedDbService,
        private datePipe: DatePipe
    ) {}

    // TODO: Date to DatePipe
    public parseDateToString(date: IDateObject): string {
        const month = date.month < 10 ? `0${date.month}` : date.month;
        const day = date.day < 10 ? `0${date.day}` : date.day;
        const dateString = `${date.year}-${month}-${day} 00:00:00`;
        return dateString;
    }

    public parsedDateToObj(date: number): IDateObject {
        const dateISO = new Date(date);
        const dateObj = {
            year: dateISO.getFullYear(),
            month: dateISO.getMonth() + 1,
            day: dateISO.getDate(),
        };
        return dateObj;
    }

    allOrdersSinceComplete(date: number): OrderModel[] {
        const orders = this.orderService.ordersToViewByCourier.filter(
            (ord) =>
                ord.deliveryDate >=
                this.parseDateToString(this.parsedDateToObj(date))
        );
        return orders;
    }

    ordersTaken(date: number): OrderModel[] {
        return this.allOrdersSinceComplete(date).filter(
            (ord) => ord.currentStatus > 2
        );
    }

    async initPendingDate() {
        const dateNow = Date.now();
        const dateNowObj = this.parsedDateToObj(dateNow);

        await this.loadPendingDate();

        if (!this.pendingDate) {
            if (this.orderService.ordersWaitingToReceive.length) {
                const date = this.orderService.ordersWaitingToReceive.sort(
                    (a, b) =>
                        Date.parse(a.deliveryDate) - Date.parse(b.deliveryDate)
                );
                this.savePendingDate(Date.parse(date[0].deliveryDate));
            } else {
                this.savePendingDate(dateNow);
            }
        }

        const pendingDateObj = this.parsedDateToObj(this.pendingDate);

        if (
            !this.allOrdersSinceComplete(this.pendingDate).length &&
            this.orderService.ordersToViewByCourier.filter(
                (ord) => ord.statusText === OrderStatusesTextEnum.COURIER_ASSIGN
            ).length > 0
        ) {
            await this.indexedDbService.deleteDir('pendingDate');
            await this.initPendingDate();
        }

        if (
            this.ordersTaken(this.pendingDate).length ===
            this.allOrdersSinceComplete(this.pendingDate).length
        ) {
            await this.completeFlagSwitch(true);
        } else {
            await this.completeFlagSwitch(false);
        }
        if (this.completeFlag) {
            if (
                this.parseDateToString(dateNowObj) >=
                this.parseDateToString(pendingDateObj)
            ) {
                return await this.savePendingDate(dateNow);
            }
        }
    }

    async completeFlagSwitch(data: boolean) {
        await this.indexedDbService.saveData({
            folder: 'pendingDate',
            fileName: 'completeFlag',
            fileData: data.toString(),
        });
    }

    async savePendingDate(date: number) {
        await this.indexedDbService.saveData({
            folder: 'pendingDate',
            fileName: 'Date',
            fileData: date.toString(),
        });
        await this.loadPendingDate();
    }

    async loadPendingDate() {
        this.pendingDate = 0;
        try {
            const dir = await this.indexedDbService.readDir('pendingDate');
            await this.loadFileData(dir.files);
            const date = this.pendingDate;
            return date;
        } catch (e) {
            await this.indexedDbService.createDir('pendingDate');
        }
    }

    async loadFileData(fileNames: string[]) {
        for (let f of fileNames) {
            const readFile = await this.indexedDbService.readFile(
                'pendingDate',
                f
            );
            switch (f) {
                case 'Date': {
                    this.pendingDate = +readFile.data;
                    break;
                }
                case 'completeFlag': {
                    this.completeFlag = readFile.data === 'true';
                    break;
                }
            }
        }
    }
}
