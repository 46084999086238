import { Mixin } from './mixin.decorator';
import { ImplicitObjectMixin } from './implicit-object';

const propName = `<>__$loadCount`;

@Mixin(ImplicitObjectMixin)
export class LoadingMixin {
    startLoading() {
        (this as any).incrementProp(propName);
    }

    stopLoading() {
        (this as any).decrementProp(propName);
    }

    get loading() {
        return (this as any).getProp(propName) > 0;
    }
}
