export enum RootRoutes {
    ROOT = 'root',
    ORDERS = 'orders',
    FINANCE = 'finance',
    PROFILE = 'profile',
    QNA = 'qna',
    START_ONBOARDING = 'start-onboarding',
}

export enum AuthRoutes {
    LOGIN = 'login',
    FORGOT_PASSWORD = 'forgot-password',
    RESET_PASSWORD = 'reset-password',
    CREATE_PASSWORD = 'create-password',
    PASSWORD_CHANGED = 'password-changed',
    AUTH = 'auth',
}

export enum OrderRoutes {
    LIST = 'list',
    ORDER_VIEW = 'order-detail/:orderId',
    ORDER_WAITING = 'waiting',
}

export enum FinanceRoutes {
    LIST = 'list',
    FINANCE_VIEW = 'finance-detail',
}

export enum QnaRoutes {
    FAQ = 'faq',
    ASK = 'ask',
}

export enum ProfileRoutes {
    INFO = 'info',
    SETTINGS = 'settings',
}

export enum PinCodeRoutes {
    PIN_CODE = 'pin-code',
    CREATE = 'create',
    CONFIRM = 'confirm',
    CHANGE = 'change',
}
