import { CanLoad } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
    InputRequiredEnum,
    PinCodeService,
} from 'src/app/services/pin-code.service';
import { filter, switchMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PinCodeGuard implements CanLoad {
    constructor(private pinCodeService: PinCodeService) {}

    canLoad(): Observable<boolean> {
        return this.pinCodeService.isInputRequired.pipe(
            filter((val) => val !== null),
            take(1),
            switchMap((inputRequired) => {
                switch (inputRequired) {
                    case InputRequiredEnum.NO_NEED: {
                        return of(true);
                    }

                    case InputRequiredEnum.REQUIRED:
                    case InputRequiredEnum.PIN_CODE_DOES_NOT_EXIST: {
                        return this.pinCodeService.openPinCodePage$();
                    }

                    default: {
                        return of(false);
                    }
                }
            })
        );
    }
}
