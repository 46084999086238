import { OrderStatusesEnum } from 'src/app/enums/order-statuses.enum';
import { OrderStatusesTextEnum } from 'src/app/enums/order-statuses-text.enum';
import { getStatusText } from 'src/app/helpers/getStatusText';

export class AvailableStatusesModel {
    currentStatus: OrderStatusesEnum;
    availableStatuses: OrderStatusesEnum[] = [];

    get nextStatusAvailable(): OrderStatusesEnum | undefined {
        return this.availableStatuses
            .filter((x) => x !== OrderStatusesEnum.REJECTED)
            .find((status) => this.currentStatus < status);
    }

    get previousStatusAvailable(): OrderStatusesEnum | undefined {
        return this.availableStatuses
            .filter((x) => x !== OrderStatusesEnum.REJECTED)
            .find((status) => this.currentStatus > status);
    }

    get isOrderOnWay(): boolean {
        return this.availableStatuses.some(
            (status) => status === OrderStatusesEnum.BUYOUT
        );
    }

    get statusText(): OrderStatusesTextEnum {
        return getStatusText(this.currentStatus);
    }

    get previousStatusText(): OrderStatusesTextEnum {
        return getStatusText(this.previousStatusAvailable);
    }
}
