import {
    animate,
    style,
    transition,
    trigger,
    AnimationTriggerMetadata,
} from '@angular/animations';

export const inOutAnimationFooter: AnimationTriggerMetadata = trigger(
    'inOutAnimationFooter',
    [
        transition(':enter', [
            style({ bottom: '-56px' }),
            animate('.4s ease-out', style({ bottom: 0 })),
        ]),
        transition(':leave', [
            style({ bottom: 0 }),
            animate('.4s ease-in', style({ bottom: '-56px' })),
        ]),
    ]
);
