export enum OrderStatusesEnum {
    /**
     * Принят, ожидает распределения
     */
    TO_ASSIGN = 1,
    /**
     * Назначен, ожидает курьера
     */
    COURIER_ASSIGN = 2,
    /**
     * Покинул склад
     */
    IN_DELIVERY = 3,
    /**
     * Курьер в пути
     */
    ON_WAY = 4,
    /**
     * Отказ
     */
    REJECTED = 5,
    /**
     * Доставлен
     */
    BUYOUT = 6,
    /**
     * Курьер передал деньги
     */
    PAID = 7,
    /**
     * Архив
     */
    ARCHIVE = 8,
}
