export const calculateRemainingSecondsUntilTimerExpires = (
    secondLimit: number,
    exhaustionDate: number,
    currentDateNow = Date.now(),
): number => {
    const delta = currentDateNow - exhaustionDate;
    const value = Number((delta / 1000).toFixed(0));

    return secondLimit - value;
};
