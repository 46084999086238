import {IFinanceGroup} from 'src/app/interfaces';


export class FinanceGroupModel {
    deliveryDate: string;
    countOrders: number;
    price: number;
    allFinished: boolean;

    public static fromDto(data: IFinanceGroup): FinanceGroupModel {
        const model = new FinanceGroupModel();

        model.deliveryDate = data.delivery_date;
        model.countOrders = data.count_orders;
        model.price = data.price;
        model.allFinished = data.all_finished;

        return model;
    }
}
