import { Injectable } from '@angular/core';
import { UserApiService } from 'src/app/api/user-api-service';
import { UserModel } from 'src/app/models/user.model';
import { map, switchMap, tap } from 'rxjs/operators';
import { combineLatest, from, Observable, of } from 'rxjs';
import { OrderApiService } from 'src/app/api/order-api-service';
import { LogrocketService } from 'src/app/services/logrocket.service';
import { environment } from 'src/environments/environment';
import { StatusesFlowService } from 'src/app/services/statuses-flow.service';
import { Storage } from '@capacitor/storage';
import { DATA_USERS } from 'src/app/constants';
import { StatusesFlowModel } from 'src/app/models/statuses-flow.model';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    user: UserModel;

    get currentRole(): string {
        return this.user?.role || 'guest';
    }

    get userId(): number {
        return this.user?.id || 0;
    }

    constructor(
        private userApiService: UserApiService,
        private orderApiService: OrderApiService,
        private logrocketService: LogrocketService,
        private statusesFlowService: StatusesFlowService
    ) {}

    static initUserDataInLocalStorage$(userId: number): Observable<void> {
        return from(Storage.get({ key: DATA_USERS })).pipe(
            map((res) => JSON.parse(res.value)),
            map((parsedUserData) => {
                if (!parsedUserData || !parsedUserData[userId]) {
                    return Object.assign(parsedUserData || {}, {
                        [userId]: { pinCode: null, orders: {} },
                    });
                }

                return null;
            }),
            switchMap((defaultData) =>
                defaultData
                    ? from(
                          Storage.set({
                              key: DATA_USERS,
                              value: JSON.stringify(defaultData),
                          })
                      )
                    : of(null)
            )
        );
    }

    clearUser() {
        this.user = null;
    }

    initUser$(): Observable<[void, StatusesFlowModel]> {
        return combineLatest([
            this.userApiService.getProfile(),
            this.orderApiService.getStatistic(),
        ]).pipe(
            tap(([userData, statData]) => {
                this.user = UserModel.fromDto(userData, statData);

                if (environment.production) {
                    this.logrocketService.identifyUser(
                        this.user.id,
                        this.user.firstName,
                        this.user.lastName,
                        this.user.email
                    );

                    this.logrocketService.init();
                }
            }),
            switchMap(([userData]) =>
                combineLatest([
                    UserService.initUserDataInLocalStorage$(userData.id),
                    this.statusesFlowService.initFlow$(userData.role),
                ])
            )
        );
    }
}
