import {forkJoin, Observable} from 'rxjs';
import {I18nApiService} from 'src/app/api/i18n-api-service';
import {catchError, map} from 'rxjs/operators';
import {ILangData} from 'src/app/interfaces/lang-list.interface';
import {ToastService} from 'src/app/services/toast.service';


export class CustomTranslateHttpLoader {
    constructor(
        private readonly i18nApiService: I18nApiService,
        private readonly toastService: ToastService,
    ) {}

    private static initDictionary(langList: ILangData[]): Record<string, Record<string, string>> {
        return langList
            .map((langItem) => langItem.char_code)
            .reduce((acc, code) => {
                if (code === 'en') {
                    return acc;
                }

                if (!acc[code]) {
                    acc[code] = {};
                }

                return acc;
            }, {});
    }

    private static fillingInDictionaryData(
        data: Record<string, any>,
        translationDictionary: Record<string, Record<string, string>>,
    ): Record<string, Record<string, string>> {
        for (const [translateDataKey, translateValue] of Object.entries(data)) {
            for (const langKey in translationDictionary) {
                if (Object.hasOwnProperty.call(translateValue, langKey)) {
                    Object.assign(
                        translationDictionary[langKey],
                        { [translateDataKey]: translateValue[langKey] },
                    );
                }
            }
        }

        return translationDictionary;
    }

    getTranslation(charCode: string): Observable<Record<string, string> | void> {
        const arr$: [Observable<Record<string, any>>, Observable<ILangData[]>] = [
            this.i18nApiService.getTranslationData(),
            this.i18nApiService.getLangList(),
        ];

        return forkJoin(arr$).pipe(
            map((res) => {
                const dictionary = CustomTranslateHttpLoader.initDictionary(res[1]);
                const filledDictionary = CustomTranslateHttpLoader.fillingInDictionaryData(res[0], dictionary);

                return filledDictionary[charCode];
            }),
            catchError(() => this.toastService.showDangerToast$('Failed to load a translation dictionary.')),
        );
    }
}
