import {CustomTranslateHttpLoader} from './CustomTranslateHttpLoader';
import {I18nApiService} from 'src/app/api/i18n-api-service';
import {ToastService} from 'src/app/services/toast.service';


const createTranslateLoader = (
    i18nApiService: I18nApiService,
    toastService: ToastService,
) => new CustomTranslateHttpLoader(
    i18nApiService,
    toastService,
);


export default createTranslateLoader;
