import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { shakeAnimation } from 'src/app/animations/shake.animation';

@Component({
    selector: 'app-mobile-dial',
    templateUrl: './mobile-dial.component.html',
    styleUrls: ['./mobile-dial.component.scss'],
    animations: [shakeAnimation],
})
export class MobileDialComponent implements OnChanges {
    @Input() valueIsNotValid = false;
    @Input() disabledDial = false;
    @Input() shakeStart = false;
    @Input() create = true;

    @Output() changePinCode: EventEmitter<string> = new EventEmitter();
    @Output() logout: EventEmitter<any> = new EventEmitter();

    #pinCode = '';

    get shakeIt(): 'shakestart' | 'shakeend' {
        return this.shakeStart ? 'shakestart' : 'shakeend';
    }

    get #pinCodeLength(): number {
        if (this.valueIsNotValid) {
            return -1;
        }

        return this.#pinCode.length;
    }

    get firstCircleColor(): 'primary' | 'light' | 'danger' {
        switch (this.#pinCodeLength) {
            case -1:
                return 'danger';
            case 0:
                return 'light';
            case 1:
            case 2:
            case 3:
            case 4:
                return 'primary';
            default:
                return 'light';
        }
    }

    get secondCircleColor(): 'primary' | 'light' | 'danger' {
        switch (this.#pinCodeLength) {
            case -1:
                return 'danger';
            case 0:
            case 1:
                return 'light';
            case 2:
            case 3:
            case 4:
                return 'primary';
            default:
                return 'light';
        }
    }

    get thirdCircleColor(): 'primary' | 'light' | 'danger' {
        switch (this.#pinCodeLength) {
            case -1:
                return 'danger';
            case 0:
            case 1:
            case 2:
                return 'light';
            case 3:
            case 4:
                return 'primary';
            default:
                return 'light';
        }
    }

    get fourthCircleColor(): 'primary' | 'light' | 'danger' {
        switch (this.#pinCodeLength) {
            case -1:
                return 'danger';
            case 0:
            case 1:
            case 2:
            case 3:
                return 'light';
            case 4:
                return 'primary';
            default:
                return 'light';
        }
    }

    #clear() {
        this.#pinCode = '';
        this.changePinCode.emit('');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            !changes?.valueIsNotValid?.currentValue &&
            changes?.valueIsNotValid?.previousValue
        ) {
            this.#clear();
        }
    }

    handleChangePinCode(value: number) {
        if (this.#pinCodeLength >= 4 || this.#pinCodeLength === -1) {
            return;
        }

        const newValue = `${this.#pinCode}${value}`;

        this.#pinCode = newValue;

        this.changePinCode.emit(newValue);
    }

    deleteLastCharacter() {
        if (this.#pinCodeLength) {
            const newValue = this.#pinCode.slice(0, -1);

            this.#pinCode = newValue;

            this.changePinCode.emit(newValue);
        }
    }
}
