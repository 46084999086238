import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

const FULL_DASH_ARRAY = 283;

@Component({
    selector: 'app-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss'],
})
export class CounterComponent implements OnChanges {
    @Input() iconName? = 'checkmark-outline';
    @Input() timeLimit? = 20;
    @Input() width? = 150;
    @Input() height? = 150;

    fullTime = null;
    timePassed;
    strokeDasharray = FULL_DASH_ARRAY.toString();
    timeLeft;

    get size() {
        return {
            width: `${this.width}px`,
            height: `${this.height}px`,
        };
    }

    get isShowIcon() {
        return this.iconName !== '';
    }

    static formatTimeLeft(time: number): number | string {
        const seconds = time % 60;

        return seconds < 10 ? `0${seconds}` : seconds;
    }

    #updateStrokeDasharray() {
        let asset: number;
        asset =
            this.timePassed -
            (this.timePassed -
                (1 / this.timePassed) * (this.timePassed - this.timeLeft)) +
            (this.timePassed - 1) * 0.05;
        this.strokeDasharray = `${(
            ((this.timeLeft - asset) / this.timePassed) *
            FULL_DASH_ARRAY
        ).toFixed(0)} ${FULL_DASH_ARRAY}`;
    }

    #updateTimeLimit(currentValue: number, previousValue: number) {
        if (!previousValue && currentValue) {
            this.timePassed = currentValue;
        }

        this.timeLeft = currentValue;

        this.fullTime = CounterComponent.formatTimeLeft(this.timeLeft);

        this.#updateStrokeDasharray();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.timeLimit) {
            this.#updateTimeLimit(
                changes.timeLimit.currentValue,
                changes.timeLimit.previousValue
            );
        }
    }
}
