import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';
import {I18nApiService} from 'src/app/api/i18n-api-service';
import {Md5} from 'ts-md5/dist/md5';
import {TRANSLATIONS_ADDED_PREVIOUSLY} from 'src/app/constants';


export class CustomMissingTranslationHandler implements MissingTranslationHandler {
    #previousTranslationsHashes: Set<any>;

    constructor(private readonly i18nApiService: I18nApiService) {
        this.#previousTranslationsHashes = new Set(
            JSON.parse(localStorage.getItem(TRANSLATIONS_ADDED_PREVIOUSLY)) || [],
        );
    }

    #updateLSPrevHashes(): void {
        localStorage.setItem(
            TRANSLATIONS_ADDED_PREVIOUSLY,
            JSON.stringify([...this.#previousTranslationsHashes.keys()]),
        );
    }

    public handle(params: MissingTranslationHandlerParams): string {
        const {
            currentLang,
            translations,
        } = params.translateService.store;

        if (currentLang === 'en') {
            if (params?.interpolateParams) {
                params.key = params.translateService.parser.interpolate(params.key, params.interpolateParams);
            }

            return params.key;
        }

        const hashKey = Md5.hashStr(`courier|${params.key}`);

        let elem = translations[currentLang] && translations[currentLang][hashKey];

        if (!elem) {
            if (!this.#previousTranslationsHashes.has(hashKey)) {
                this.i18nApiService.addTranslation('courier', params.key).subscribe({
                    next: () => {
                        this.#previousTranslationsHashes.add(hashKey);
                        this.#updateLSPrevHashes();
                    },
                    error: (e) => console.error(e),
                });
            }

            if (params?.interpolateParams) {
                params.key = params.translateService.parser.interpolate(params.key, params.interpolateParams);
            }

            return params.key;
        }

        if (elem && this.#previousTranslationsHashes.has(hashKey)) {
            this.#previousTranslationsHashes.delete(hashKey);

            this.#updateLSPrevHashes();
        }

        if (params?.interpolateParams) {
            elem = params.translateService.parser.interpolate(elem, params.interpolateParams);
        }

        return elem;
    }
}
