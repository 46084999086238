import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IFlow } from 'src/app/interfaces';
import { CheckInternetSpeed } from 'src/app/services/check-internet-speed.service';

@Injectable({
    providedIn: 'root',
})
export class OrderFlowApiService {
    constructor(
        private http: HttpClient,
        private checkInternetSpeed: CheckInternetSpeed
    ) {}

    getDefaultFlow(): Observable<IFlow> {
        this.checkInternetSpeed.testSpeed();
        return this.http.get('/order-flow/default').pipe(pluck('data'));
    }
}
