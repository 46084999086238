import {Injectable} from '@angular/core';
import {StatusesFlowModel} from 'src/app/models/statuses-flow.model';
import {OrderFlowApiService} from 'src/app/api/order-flow-api-service';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class StatusesFlowService {
    statusesFlowModel: StatusesFlowModel;

    constructor(private readonly orderFlowApiService: OrderFlowApiService) {}

    initFlow$(currentRole: string): Observable<StatusesFlowModel> {
        return this.orderFlowApiService.getDefaultFlow()
            .pipe(
                map(flowRes => StatusesFlowModel.fromDto(flowRes, currentRole)),
                tap(res => this.statusesFlowModel = res)
            );
    }
}
