import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { OrderApiService } from 'src/app/api/order-api-service';
import { map, switchMap, tap } from 'rxjs/operators';
import { OrderModel } from 'src/app/models/order.model';
import { OrderStatusesEnum } from 'src/app/enums/order-statuses.enum';
import { ModalController, ModalOptions } from '@ionic/angular';
import { ManualChangeoverStatusComponent } from 'src/app/modals/manual-changeover-status/manual-changeover-status.component';
import { StatusesFlowService } from 'src/app/services/statuses-flow.service';
import { UserService } from 'src/app/services/user.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { OrderTimersService } from 'src/app/services/order-timers.service';
import { OrderStatusesTextEnum } from 'src/app/enums/order-statuses-text.enum';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    public orders: BehaviorSubject<OrderModel[]> = new BehaviorSubject<
        OrderModel[]
    >([]);

    get allOrders(): OrderModel[] {
        return this.orders.value;
    }

    get ordersToChangeStatus(): OrderModel[] {
        return this.allOrders.filter(OrderService.isOrderToChangeStatus);
    }

    get ordersToViewByCourier(): OrderModel[] {
        return this.allOrders
            .filter(
                (order) =>
                    OrderService.isOrderToChangeStatus(order) ||
                    order.currentStatus === OrderStatusesEnum.BUYOUT
            )
            .filter((order) =>
                order.countryId
                    ? order.countryId === this.userService.user.countryId
                    : true
            );
    }

    get courierAssignOrders(): OrderModel[] {
        return this.ordersToChangeStatus.filter(
            (order) => order.currentStatus === OrderStatusesEnum.COURIER_ASSIGN
        );
    }

    get numberOfOrdersTaken(): string {
        const allOrdersLength = this.ordersToChangeStatus.length;

        return `${
            allOrdersLength - this.courierAssignOrders.length
        }/${allOrdersLength}`;
    }

    get availableOrderStatuses(): OrderStatusesTextEnum[] {
        return OrderService.getAllOrderStatuses(this.ordersToChangeStatus);
    }

    get availableForViewingStatuses(): OrderStatusesTextEnum[] {
        return OrderService.getAllOrderStatuses(
            this.ordersToViewByCourier
        ).filter((v) => v !== 'Waiting to receive');
    }

    get ordersWaitingToReceive(): OrderModel[] {
        return this.ordersToViewByCourier.filter(
            (order) => order.statusText === OrderStatusesTextEnum.COURIER_ASSIGN
        );
    }

    get ordersOnWay(): OrderModel[] {
        return this.ordersToViewByCourier.filter(
            (order) => order.statusText === OrderStatusesTextEnum.ON_WAY
        );
    }

    constructor(
        private readonly orderApiService: OrderApiService,
        private readonly modalCtrl: ModalController,
        private readonly statusesFlowService: StatusesFlowService,
        private readonly userService: UserService,
        private readonly localStorageService: LocalStorageService,
        private readonly orderTimerService: OrderTimersService
    ) {}

    private static isOrderToChangeStatus(order: OrderModel): boolean {
        return (
            order.currentStatus >= OrderStatusesEnum.COURIER_ASSIGN &&
            order.currentStatus <= OrderStatusesEnum.ON_WAY
        );
    }

    private static getAllOrderStatuses(
        orders: OrderModel[]
    ): OrderStatusesTextEnum[] {
        const statuses = orders
            .sort((a, b) => (a.currentStatus > b.currentStatus ? 1 : -1))
            .map((order) => order.statusText);

        const set = new Set(statuses);

        return [...set.values()];
    }

    openModalManualChangeover(switcher?: string): Observable<void> {
        const modalOpts: ModalOptions = {
            component: ManualChangeoverStatusComponent,
            componentProps: switcher ? { propStatus: switcher } : null,
        };
        return from(this.modalCtrl.create(modalOpts)).pipe(
            switchMap((modal) => modal.present())
        );
    }

    findAnOrderByQRCode(trackNumber: string): OrderModel | undefined {
        return this.allOrders.find((order) => order.track === trackNumber);
    }

    getOrderList$(): Observable<OrderModel[]> {
        return this.orderApiService.getCourierOrderList().pipe(
            map((res) =>
                res.map((order) => {
                    const orderModel = OrderModel.fromDto(order);

                    orderModel.availableStatuses =
                        this.statusesFlowService.statusesFlowModel.getAvailableStatusesForTransition(
                            orderModel.currentStatus,
                            this.userService.currentRole
                        );

                    return orderModel;
                })
            ),
            tap((orders) => this.orders.next(orders))
        );
    }

    initTimersForOrders$() {
        return this.getOrderList$().pipe(
            switchMap((orders) =>
                this.localStorageService.initOrdersLocalStorage$(
                    orders.map(({ orderId }) => orderId),
                    this.userService.userId
                )
            ),
            switchMap((res) => this.orderTimerService.createdTimers$(res))
        );
    }
}
