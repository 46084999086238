import { OrderStatusesEnum } from 'src/app/enums/order-statuses.enum';
import { OrderStatusesTextEnum } from 'src/app/enums/order-statuses-text.enum';

export const getStatusText = (
    statusId: OrderStatusesEnum
): OrderStatusesTextEnum => {
    switch (statusId) {
        case OrderStatusesEnum.TO_ASSIGN:
            return OrderStatusesTextEnum.TO_ASSIGN;
        case OrderStatusesEnum.COURIER_ASSIGN:
            return OrderStatusesTextEnum.COURIER_ASSIGN;
        case OrderStatusesEnum.IN_DELIVERY:
            return OrderStatusesTextEnum.IN_DELIVERY;
        case OrderStatusesEnum.ON_WAY:
            return OrderStatusesTextEnum.ON_WAY;
        case OrderStatusesEnum.REJECTED:
            return OrderStatusesTextEnum.REJECTED;
        case OrderStatusesEnum.BUYOUT:
            return OrderStatusesTextEnum.BUYOUT;
        case OrderStatusesEnum.PAID:
            return OrderStatusesTextEnum.PAID;
        case OrderStatusesEnum.ARCHIVE:
            return OrderStatusesTextEnum.ARCHIVE;
        default:
            return OrderStatusesTextEnum.UNDEFINED;
    }
};
