import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
import { IUser } from 'src/app/interfaces';

export type TLoginResponse = { readonly token: string };
export type TLogoutResponse = { readonly message: string };

@Injectable({
    providedIn: 'root',
})
export class UserApiService {
    constructor(private readonly http: HttpClient) {}

    login(email: string, password: string): Observable<TLoginResponse> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Content-Disposition': 'body',
            },
        };

        return this.http.post<TLoginResponse>(
            '/user/login',
            { email, password },
            options
        );
    }

    logout(): Observable<TLogoutResponse> {
        return this.http.post<TLogoutResponse>('/user/logout', {});
    }

    getProfile(): Observable<IUser> {
        return this.http.get('/user/profile').pipe(pluck('data'));
    }

    forgotPassword(email: string): Observable<any> {
        return this.http
            .post('/user/forgot-password', { email })
            .pipe(pluck('data'));
    }

    resetCheck(token: string, email: string): Observable<any> {
        return this.http
            .post(`/user/reset-check/${token}`, { email })
            .pipe(pluck('data'));
    }

    resetPassword(
        token: string,
        email: string,
        password: string
    ): Observable<any> {
        return this.http
            .post('/user/reset-password', { token, email, password })
            .pipe(pluck('data'));
    }
}
