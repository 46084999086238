import { Injectable } from '@angular/core';
import { from, Observable, ObservableInput } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Storage } from '@capacitor/storage';
import { DATA_USERS } from '../constants';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    updateChangeOfStatusDateOrderById$(
        orderIds: number[],
        userId: number,
        dateUpdate = Date.now()
    ): Observable<any> {
        return this.initOrdersLocalStorage$(orderIds, userId).pipe(
            map((orders) =>
                orderIds.reduce((acc, orderId) => {
                    if (acc[orderId]) {
                        acc[orderId].changeOfStatusDate = dateUpdate;
                    }

                    return acc;
                }, orders)
            ),
            switchMap((orders) => this.#setOrdersLocalStorage(orders, userId))
        );
    }

    initOrdersLocalStorage$(
        orderIds: number[],
        userId: number
    ): Observable<
        Observable<Record<string, any>> extends ObservableInput<infer T>
            ? T
            : never
    > {
        return this.getOrdersLocalStorage(userId).pipe(
            map((orders) =>
                orderIds.reduce((acc, orderId) => {
                    if (!acc[orderId]) {
                        acc[orderId] = {
                            changeOfStatusDate: null,
                            callAttempt: false,
                        };
                    }

                    return acc;
                }, orders)
            ),
            switchMap((res) =>
                this.#setOrdersLocalStorage(res, userId).pipe(map((_) => res))
            )
        );
    }

    hasCallAttemptByOrderId$(
        orderId: number,
        userId: number
    ): Observable<boolean> {
        return from(this.getOrdersLocalStorage(userId)).pipe(
            map((res) => res[orderId].callAttempt)
        );
    }

    #setOrdersLocalStorage(
        value: Record<number, boolean>,
        userId: number
    ): Observable<any> {
        return from(Storage.get({ key: DATA_USERS })).pipe(
            map((res) => {
                if (res.value) {
                    const userData = JSON.parse(res.value);

                    userData[userId].orders = value;

                    return JSON.stringify(userData);
                }
            }),
            switchMap((savedData) =>
                from(
                    Storage.set({
                        key: DATA_USERS,
                        value: savedData,
                    })
                )
            )
        );
    }

    getOrdersLocalStorage(userId: number): Observable<Record<string, any>> {
        return from(Storage.get({ key: DATA_USERS })).pipe(
            map((res) => {
                if (res.value) {
                    const userData = JSON.parse(res.value);

                    return userData[userId].orders;
                } else {
                    return {};
                }
            })
        );
    }

    indicateACallAttemptOrderById$(
        orderId: number,
        userId: number
    ): Observable<any> {
        return this.getOrdersLocalStorage(userId).pipe(
            map((callAttempts) => {
                if (callAttempts[orderId].callAttempt) {
                    return callAttempts;
                } else {
                    callAttempts[orderId].callAttempt = true;
                }

                return callAttempts;
            }),
            switchMap((savedData) =>
                this.#setOrdersLocalStorage(savedData, userId)
            )
        );
    }
}
