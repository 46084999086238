import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @Input() stateHeader?: 'main' | 'searchField' = 'main';
    @Input() progress?: boolean = false;
    @Input() page = '';

    constructor() {}
}
